import { useState, useEffect, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { copyToClipboard } from "../components/CopyButton";
import appList from "../app-list.json";

export default function SVGEditor() {
    const [currentImage, setCurrentImage] = useState('');
    const [currentTheme, setCurrentTheme] = useState("dark");

    const loadSampleImage = () => {
        const enabledApps = appList.filter((app) => app.is_enabled);

        // Get a random icons
        const randomIndex = Math.floor(Math.random() * enabledApps.length);
        const randomEnabledApp = enabledApps[randomIndex];

        // Fetch 
        const fetchSvgContent = async () => {
            try {
                const response = await fetch(randomEnabledApp.icon);
                const svgText = await response.text();
                setCurrentImage(btoa(svgText));
            } catch (error) {
                console.error('Error fetching SVG:', error);
            }
        };
        fetchSvgContent();
    }

    useEffect(() => {
        loadSampleImage();
    }, []);

    const handleBgColorMode = () => {
        const newTheme = currentTheme === "dark" ? "light" : "dark";
        console.log("New Theme:", newTheme);
        setCurrentTheme(newTheme);
    };

    const handleCopyImageSource = () => {
        console.log('Copying image source...');
        const decodedBase64SString = atob(currentImage);
        console.log(decodedBase64SString)
        copyToClipboard(decodedBase64SString)
    };

    const handleInputChange = useCallback((value) => {
        setCurrentImage(btoa(value));
    }, []);

    const handlePasteFromClipboard = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            console.log('Image from clipboard:', clipboardText);
            setCurrentImage(btoa(clipboardText));
        } catch (error) {
            console.error("Error reading from clipboard", error);
        }
    };

    return (
        <div className={`h-screen flex ${currentTheme}`}>
            <div className="w-full mx-6 my-4">
                <div className={`w-full flex items-center font-bold mb-3 ${currentTheme === "dark" ? "dark" : ""}`}>SVG Editor</div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div className="flex mb-2">
                            <div className="mb-2 inline-block">Input:</div>
                            <div className="inline-block ml-3">
                                <button className="btn btn-xs  mr-1" onClick={handlePasteFromClipboard}>
                                    Clipboard
                                </button>

                                <button className="btn btn-xs  mr-1" onClick={loadSampleImage}>
                                    Sample
                                </button>
                            </div>
                        </div>

                        <CodeMirror
                            className={`bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-lg focus:outline-none focus:ring focus:border-blue-500 
                            overflow-hidden min-h-[78.5vh] max-h-[78.5vh] ${currentTheme === "dark" ? "dark" : ""}`}
                            placeholder="Enter text here"
                            extensions={[EditorView.lineWrapping]}
                            value={atob(currentImage)}
                            onChange={handleInputChange}
                            height="78.5vh"
                            theme={dracula}
                        />
                    </div>
                    <div>
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 py-[5px] px-[10px]">
                                <p>Output:</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <button className="btn btn-xs" onClick={handleBgColorMode}>
                                    {currentTheme === "dark" ? "Light Mode" : "Dark Mode"}
                                </button>
                                <button className="btn btn-xs" onClick={handleCopyImageSource}>
                                    Copy 📋
                                </button>
                            </div>
                        </div>

                        <div className={`p-2 ${currentTheme === 'dark' ? 'bg-black' : 'bg-white'} rounded-lg`}>
                            <img
                                src={`data:image/svg+xml;base64,${currentImage}`}
                                alt="SVG Output"
                                onLoad={() => console.log("Image loaded")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}