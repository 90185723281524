export default function SVGtoSVGR() {




return (
    <>
    <div className="mx-6 my-4">
    <div className="w-full flex items-center font-bold mb-3">
          SVG To SVGR
        </div>
    <embed src="https://react-svgr.com/playground/?memo=true&native=true" className="w-screen h-screen" />
    </div>
    </>
)
}