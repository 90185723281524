import {useEffect, useState} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {EditorView} from "codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import toast from "react-hot-toast";
import {copyToClipboard} from "../components/CopyButton";

// Load syntax highlighter
import {javascriptLanguage} from '@codemirror/lang-javascript';
import {typescriptLanguage} from '@codemirror/lang-javascript';
import {jsonLanguage} from '@codemirror/lang-json';
import {phpLanguage} from '@codemirror/lang-php';
import {markdownLanguage} from '@codemirror/lang-markdown';
import {htmlLanguage} from '@codemirror/lang-html';
import {observer} from "mobx-react-lite";
import {useStore} from "../index";

export const getLangs = (name: string) => {
    const langs: {[key: string]: any} = {
        'json': jsonLanguage,
        'php': phpLanguage,
        'javascript': javascriptLanguage,
        'typescript': typescriptLanguage,
        'markdown': markdownLanguage,
        'html': htmlLanguage,
    }
    return langs[name]
}

const LLMChat = observer(() => {
    // Stores
    const {llmStore} = useStore();
    const {
        setModelIndex,
        llmHandleSubmit,
        modelIndex,
        availableModels,
        isLlmLoading,
        executionTime,
        resultText,
        errorMessage,
        isLlmSuccess,
    } = llmStore;

    // State
    const [inputText, setInputText] = useState("Write React function to display on off switch using mobx, show code only");

    const handlePasteFromClipboard = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setInputText(clipboardText);
        } catch (error) {
            console.error("Error paste from clipboard: " + error);
        }
    };

    const handleSubmit = () => {
        llmHandleSubmit({
            prompt: inputText,
        });
    };

    useEffect(() => {
        if (executionTime > 0 && isLlmLoading === false) {
            if (isLlmSuccess) {
                toast.success('Done in ' + (executionTime / 1000).toFixed(1) + 's');
            } else {
                toast.error(errorMessage);
            }
        }
    }, [executionTime, isLlmLoading, errorMessage, isLlmSuccess]);

    return (
        <div className="h-screen flex">
            <div className="w-full mx-6 my-4">
                <div className="w-full flex items-center font-bold mb-3">
                    LLM Chat
                </div>
                <div className="">
                    <div className="mb-3">
                        <div className="mb-2">
                            <div className="grid grid-cols-2 gap-0">
                                <div>
                                    <div className="inline-block mr-2">Input</div>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={handlePasteFromClipboard}
                                    >
                                        Clipboard
                                    </button>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => {
                                            setInputText('');
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>

                                <div className=" justify-end text-right">
                                    <div className="inline-block mr-1">
                                        <div>
                                            <select className="select select-xs select-bordered w-full "
                                                value={modelIndex}
                                                onChange={(e) => {
                                                    console.log('Model index: ', parseInt(e.target.value));
                                                    setModelIndex(parseInt(e.target.value))
                                                }}
                                            >

                                                {availableModels.map((model, index) => {
                                                    return <option
                                                        key={`model-${index}`}
                                                        value={index}
                                                    >{model}</option>
                                                })}
                                            </select>

                                        </div>
                                    </div>
                                    <button className="btn btn-xs "
                                        disabled={isLlmLoading}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        {
                                            isLlmLoading ? (
                                                <>Loading {(executionTime / 1000).toFixed(1)}s</>
                                            ) : (
                                                <>Convert</>
                                            )
                                        }
                                    </button>
                                </div>
                                {/* 
                                <div className=" justify-end">
                                    <select value={selectedPromptIndex}
                                        onChange={(e) => setSelectedPromptIndex(parseInt(e.target.value))} className="btn btn-xs mx-1 ">
                                        {
                                            prompts.map((item, index) => {
                                                return <option key={`prompt-${index}`} value={index}>{prompts[index].label}</option>
                                            })
                                        }
                                    </select>

                                </div> */}
                            </div>
                        </div>
                        <textarea
                            className="p-2 min-h-[20vh] max-h-[20vh] bg-gray-900 w-full border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden "
                            placeholder="Enter input here"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            disabled={isLlmLoading}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    //Prevent Enter from adding new lines
                                    e.preventDefault();
                                    // Call your action function here, for example:
                                    handleSubmit();
                                } else if (e.key === 'Enter' && e.shiftKey) {
                                    //Let Enter + Shift add new lines by default
                                }
                            }}
                        >{inputText}</textarea>
                    </div>
                    <div>
                        <div className="mb-2">
                            <div className="grid grid-cols-2 gap-0">
                                <div>
                                    <div className="inline-block mr-2">Result</div>
                                </div>

                                <div className=" justify-end text-right">
                                    <button className="btn btn-xs mr-1" onClick={() => {copyToClipboard(resultText)}}>Copy</button>

                                </div>
                                {/* 
                                <div className=" justify-end">
                                    <select value={selectedPromptIndex}
                                        onChange={(e) => setSelectedPromptIndex(parseInt(e.target.value))} className="btn btn-xs mx-1 ">
                                        {
                                            prompts.map((item, index) => {
                                                return <option key={`prompt-${index}`} value={index}>{prompts[index].label}</option>
                                            })
                                        }
                                    </select>

                                </div> */}
                            </div>
                        </div>
                        <CodeMirror
                            className="min-h-[60vh] max-h-[60vh] bg-gray-900 w-full border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 "
                            extensions={[
                                EditorView.editable.of(true),
                                EditorView.lineWrapping,
                                getLangs('markdown')
                            ]}
                            theme={dracula}
                            value={resultText}
                            height="60vh"
                        />

                    </div>
                </div>
            </div>
        </div >
    );
});

export default LLMChat;
