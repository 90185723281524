/*========================================
 * Imports: External libraries and modules
 *========================================*/

// React and utility libraries
import React, { useState, useCallback, useEffect, useRef } from "react";
import { openDB } from "idb";
import { useNavigate } from "react-router-dom";

// Chart.js and plugins for visualization
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import ZoomPlugin from 'chartjs-plugin-zoom';
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  ZoomPlugin
);

/*======================================
 * Configuration: Constants and Defaults
 *======================================*/

// Database configuration for persisting URL data
const databaseName = "devtool";   // IndexedDB database name
const tableName = "config";       // Store name in the database
const key = "multiuptimechecker"; // Key for the configuration object

/*===============================================
 * Type Definitions: Interfaces for strong typing
 *===============================================*/

/**
 * Interface: UrlData
 * Represents the configuration for a monitored URL.
 * @property {number} id - Unique identifier for the URL.
 * @property {string} url - The monitored URL.
 * @property {string} method - HTTP method (e.g., GET, POST).
 * @property {string} name - Descriptive name for the URL.
 */
interface UrlData {
  id: number;
  url: string;
  method: string;
  name: string;
}

/**
 * Interface: UrlResult
 * Represents the results of monitoring a URL.
 * @property {number} id - Identifier of the URL.
 * @property {number[]} responseTimes - List of response times (ms).
 * @property {Object} stats - Calculated statistics (min, avg, max response times).
 * @property {number} status - HTTP response status code.
 */
interface UrlResult {
  id: number;
  url: string;
  method: string;
  name: string;
  responseTimes: number[];
  stats: {
    min: number;
    avg: number;
    max: number;
  },
  status: number;
}

/*===================================
 * Main Component: MultiUptimeChecker
 *===================================*/
const MultiUptimeChecker = () => {
  /*========================================
   * State Management: React hooks for state
   *========================================*/

  /**
   * State: db
   * Represents the instance of IndexedDB used for persisting configuration data.
   * - Initially set to `null` until the database is initialized.
   * @type {IndexedDB | null}
   */
  const [db, setDb] = useState(null);

  /**
  * State: urls
  * Stores the list of monitored URLs along with their configuration details.
  * - Keys are unique IDs representing each URL.
  * - Values are objects adhering to the `UrlData` interface.
  * @type {{ [key: number]: UrlData }}
  */
  const [urls, setUrls] = useState<{ [key: number]: UrlData }>({});

  /**
   * State: urlsResult
   * Stores the results of monitoring each URL, including response times, status codes, and statistics.
   * - Keys are unique IDs corresponding to the monitored URLs.
   * - Values are objects adhering to the `UrlResult` interface.
   * @type {{ [key: number]: UrlResult }}
   */
  const [urlsResult, setUrlResult] = useState<{ [key: number]: UrlResult }>({});

  /**
   * State: isChecking
   * Indicates whether the application is actively monitoring the URLs.
   * - `true`: Monitoring is in progress.
   * - `false`: Monitoring is paused.
   * 
   * This state is toggled using the `handleStartStop` function.
   * @type {boolean}
   */
  const [isChecking, setIsChecking] = useState(false);

  /**
   * State: inputInterval
   * Determines the interval (in milliseconds) for periodic URL checks.
   * - Default value is `5000` milliseconds (5 seconds).
   * - Updated dynamically using the `handleInterval` function.
   * @type {number}
   */
  const [inputInterval, setInputInterval] = useState(5000);

  /**
   * State: link
   * Stores the generated shareable link that encodes the current configuration of monitored URLs.
   * - Updated whenever the `urls` state changes.
   * @type {string}
   */
  const [link, setLink] = useState("");

  /**
   * State: isChartModalOpen
   * Manages the visibility of the chart modal.
   * @type {boolean}
   */
  const [isChartModalOpen, setIsChartModalOpen] = useState(false);
  
  /**
   * State: selectedUrlStatus
   * Holds the ID of the currently selected URL for detailed status visualization.
   * @type {number | null}
   */
  const [selectedUrlStatus, setSelectedUrlStatus] = useState(null);

  /**
   * State: selectedUrlChartData
   * Contains data for rendering the chart in the modal.
   * @type {{labels: string[], datasets: Array}}
   */
  const [selectedUrlChartData, setSelectedUrlChartData] = useState({
    labels: [],
    datasets: [],
  });

  /**
   * Hook: useNavigate
   * Provides a method to programmatically navigate between routes using React Router.
   * - Used for updating the query parameters in the URL.
   * @type {Function}
   */
    const navigate = useNavigate();

  /*==============================
   * References: Persistent values
   *==============================*/

  /**
   * Reference: isUserInteraction
   * Tracks whether the user has interacted with the application (e.g., clicked anywhere).
   * - This is required for enabling error sounds, as audio playback often requires user interaction.
   * - Default value: `false`
   * @type {React.MutableRefObject<boolean>}
   */
  const isUserInteraction = useRef(false);

  /**
   * Reference: isParameter
   * Indicates if the application was initialized with query parameters (e.g., shared configuration link).
   * - When `true`, some features like saving data to the database are disabled.
   * - Default value: `false`
   * @type {React.MutableRefObject<boolean>}
   */
  const isParameter = useRef(false);
  
  /**
   * Reference: intervalRef
   * Holds the reference to the interval timer used for periodic monitoring.
   * - Used to start and stop the timer when toggling the `isChecking` state.
   * - Default value: `null`
   * @type {React.MutableRefObject<NodeJS.Timeout | null>}
   */
  const intervalRef = useRef(null);

  /*===============
   * Modal Handlers
   *===============*/


  
  /*====================
   * Chart Configuration
   *====================*/
  /**
   * Constant: options
   * Configuration options for rendering the response time chart.
   * - Enables zooming and panning.
   * - Displays a title dynamically based on the selected URL.
   */
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: urlsResult[selectedUrlStatus]?.url || "Response Time for Selected URL",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw} ms`;
          },
        },
      },
      zoom: {
        zoom: {
          enabled: !isChecking,
          wheel: {
            enabled: !isChecking,
          },
          pinch: {
            enabled: !isChecking,
          },
          mode: 'x' as const,
        },
        pan: {
          enabled: !isChecking,
          mode: 'x' as const,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Response Time (ms)',
        },
        beginAtZero: true,
      },
    },
  };

  /*==========
   * Utilities
   *==========*/

  /**
   * Function: decodeFromBase64
   * Decodes a Base64-encoded JSON string.
   * @param {string} data - The encoded string.
   * @returns {Object | null} - Decoded data or null if decoding fails.
   */
  const decodeFromBase64 = (data) => {
    try {
      return JSON.parse(atob(data));
    } catch (error) {
      console.error("Failed to decode data:", error);
      return null;
    }
  };

  /**
   * Function: encodeToBase64
   * Encodes an object into a Base64 string.
   * @param {Object} data - The data to encode.
   * @returns {string} - Base64-encoded string.
   */
  const encodeToBase64 = (data) => {
    return btoa(JSON.stringify(data));
  };

  /**
   * Function: playErrorSound
   * Plays an error sound for invalid or failed URL checks.
   */
  const playErrorSound = () => {
    const audio = new Audio("sounds/error.mp3");
    audio.play();
  };

  /*=====================
   * Database Persistence
   *=====================*/

  /**
   * Function: saveUrlsToDB
   * Saves the current list of monitored URLs to IndexedDB for persistence.
   * - If the `isParameter` flag is active, only updates the state without saving to the database.
   * @async
   * @param {Object} updatedUrls - Updated URLs object to save.
   */
  const saveUrlsToDB = async (updatedUrls) => {
    if (isParameter.current) {
      setUrls(updatedUrls);
      return;
    }
    if (db) {
      await db.put(tableName, { key, data: updatedUrls });
    }
  };

  /*=========================
   * User Interaction Handler
   *=========================*/

  /**
   * Function: openChartModal
   * Opens the chart modal for the selected URL.
   * @param {number} id - The ID of the selected URL.
   */
  const openChartModal = (id) => {
    setSelectedUrlStatus(id);
    setIsChartModalOpen(true)
  };

  /**
   * Function: closeChartModal
   * Closes the chart modal.
   */
  const closeChartModal = () => {
    setIsChartModalOpen(false);
  };
  
  /**
   * Function: handleAddUrl
   * Adds a new URL entry with default settings.
   */
  const handleAddUrl = async () => {
    const id = Date.now();
    const newUrl = { id, url: "", method: "GET", name: "" };
    const updatedUrls = { ...urls, [id]: newUrl };

    saveUrlsToDB(updatedUrls);
    setUrls(updatedUrls);
  };

  /**
  * Function: handleUpdateUrl
  * Updates the properties of an existing URL entry.
  * @param {number} id - The ID of the URL to update.
  * @param {Object} updatedData - Updated URL properties.
  */
  const handleUpdateUrl = (id, updatedData) => {
    if (id === "" || id === null || id === undefined) {
      return;
    };

    const updatedUrls = { ...urls, [id]: { ...urls[id], ...updatedData } };

    saveUrlsToDB(updatedUrls);
    setUrls(updatedUrls);
  };

  /**
  * Function: handleDeleteUrl
  * Deletes a URL entry and its associated results.
  * @param {number} id - The ID of the URL to delete.
  */
  const handleDeleteUrl = (id) => {
    const updatedUrls = { ...urls };
    delete updatedUrls[id];
    delete urlsResult[id];

    saveUrlsToDB(updatedUrls);
    setUrls(updatedUrls);
  };

  /**
   * Function: handleInterval
   * Updates the interval value for periodic URL checks.
   * @param {number} value - Interval in milliseconds.
   */
  const handleInterval = (value) => {
    setInputInterval(value);
  };

  /**
   * Function: handleStartStop
   * Toggles the monitoring process.
   * - Validates input before starting:
   *   1. Checks if at least one URL is added.
   *   2. Ensures all URL fields are complete (URL, method, name).
   *   3. Verifies the interval is a positive number.
   * - If validation fails, displays an alert message.
   */
  const handleStartStop = () => {
    if (Object.keys(urls).length === 0 && !isChecking) {
      alert("Please add new URL!");
      return;
    }

    if (!isChecking && Object.values(urls).some(({ url, method, name }) => !url.trim() || !method.trim() || !name.trim())) {
      alert("Please fill in all URL fields before starting!");
      return;
    }

    if (!isChecking && (isNaN(inputInterval) || inputInterval <= 0)) {
      alert("Please enter a valid positive number!");
      return;
    }

    setIsChecking((prev) => !prev);
  };

  /*=====================
   * URL Monitoring Logic
   *=====================*/

  /**
   * Function: checkUrlsStatus
   * Periodically checks the status and response time of monitored URLs.
   * - Fetches each URL and measures the response time.
   * - Updates the state with response statistics and status codes.
   * - Plays an error sound for failed responses if the user has interacted with the app.
   */
  const checkUrlsStatus = useCallback(async () => {  
    /**
     * Helper Function: updateUrlResult
     * Updates the `urlsResult` state with new response data.
     * @param {number} id - URL ID.
     * @param {string} url - Monitored URL.
     * @param {string} method - HTTP method (e.g., GET, POST).
     * @param {string} name - Descriptive name for the URL.
     * @param {number[]} responseTimes - Array of response times (ms).
     * @param {number|null} status - HTTP status code (or null if the request failed).
     */
    const updateUrlResult = (id, url, method, name, responseTimes, status) => {
      const stats = calculateStats(responseTimes);

      setUrlResult((prev) => ({
        ...prev,
        [id]: {
          id: Number(id),
          url,
          method,
          name,
          responseTimes,
          stats,
          status,
        },
      }));
    };
  
    // Perform parallel HTTP requests for all monitored URLs.
    await Promise.all(
      Object.entries(urls).map(async ([id, { url, method, name }]) => {
        try {
          // Measure the response time for the HTTP request.
          const startTime = performance.now();
          const response = await fetch(url, { method });
          const endTime = performance.now();
          const responseTime = Math.round(endTime - startTime);

          // If the URL has changed, reset its response time history.
          setUrlResult((prev) => {
            const existingData = prev[id] || {};
            const newResponseTimes = existingData.url !== url ? [] : existingData.responseTimes || [];
  
            updateUrlResult(id, url, method, name, newResponseTimes, existingData.status);
            return prev;
          });
  
          // Add the new response time and update the status after a successful request.
          setUrlResult((prev) => {
            const existingResponseTimes = prev[id]?.responseTimes || [];
            const newResponseTimes = [...existingResponseTimes, responseTime];
  
            updateUrlResult(id, url, method, name, newResponseTimes, response.status);
            return prev;
          });
  
          // Play an error sound if the response status is an error (>= 400).
          if (response.status >= 400 && isUserInteraction.current) {
            playErrorSound();
          };
        } catch (error) {

          // Play an error sound if the request fails.
          if (isUserInteraction.current) {
            playErrorSound();
          }
  
          // On failure, add a response time of 0 and set status to null.
          setUrlResult((prev) => {
            const existingResponseTimes = prev[id]?.responseTimes || [];
            const newResponseTimes = [...existingResponseTimes, 0];
  
            // Menggunakan updateUrlResult untuk memperbarui state
            updateUrlResult(id, url, method, name, newResponseTimes, null);
            return prev;
          });
        }
      })
    );
  }, [urls]);

  /**
   * Function: calculateStats
   * Computes statistics (minimum, average, and maximum) for a list of response times.
   * @param {number[]} responseTimes - Array of response times (in milliseconds).
   * @returns {Object} - Object containing `min`, `avg`, and `max` response times.
   */
  const calculateStats = (responseTimes) => {
    if (responseTimes.length === 0) return { min: 0, avg: 0, max: 0 };
  
    const min = Math.min(...responseTimes);
    const max = Math.max(...responseTimes);
    const avg = Math.round(responseTimes.reduce((a, b) => a + b, 0) / responseTimes.length);
  
    return { min, avg, max };
  };

  /*===============================
  * Initialization and Side Effects
  *================================*/

  /**
   * Effect: Update chart data when a URL's status is selected or updated.
   * - Populates `selectedUrlChartData` with response time data for the selected URL.
   */
  useEffect(() => {
    if (selectedUrlStatus && urlsResult[selectedUrlStatus]) {
      const { responseTimes } = urlsResult[selectedUrlStatus];

      const labels = responseTimes.map((_, index) => `Index ${index + 1}`);

      setSelectedUrlChartData({
        labels,
        datasets: [
          {
            label: "Response time",
            data: responseTimes,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: true,
          },
        ],
      });
    }
  }, [urlsResult, selectedUrlStatus]);

  /**
   * Effect: Initialize IndexedDB
   * Sets up the IndexedDB database for storing monitored URLs.
   * - Checks if the database and store exist; creates them if not.
   * - Loads stored data into the `urls` state on initial render.
   */
  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: "key" });
          }
        },
      });

      setDb(database);

      if (!isParameter.current) {
        const storedData = await database.get(tableName, key);
        if (storedData) {
          setUrls(storedData.data || {});
        }
      }
    };

    initDB();
  }, []);

  /**
   * Effect: Start/Stop Monitoring
   * Handles the interval-based monitoring logic.
   * - Starts or stops the interval timer based on the `isChecking` state.
   */
    useEffect(() => {
      if (isChecking) {
        checkUrlsStatus();
        intervalRef.current = setInterval(checkUrlsStatus, inputInterval);
      } else if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
      };
    }, [isChecking, checkUrlsStatus, inputInterval]);
  
  /**
   * Effect: Listen for User Interaction
   * Detects the first user interaction (e.g., a click) and enables error sounds.
   */
  useEffect(() => {
    const handleAnyInteraction = () => {
      isUserInteraction.current = true;
    };
    window.addEventListener("click", handleAnyInteraction);
  }, []);  

  /**
   * Effect: Update Chart Data
   * Dynamically updates the data for the chart when a URL's status is selected or updated.
   * - Extracts response times for the selected URL from `urlsResult`.
   * - Constructs chart labels and datasets.
   * - Updates the `selectedUrlChartData` state.
   */
  useEffect(() => {
    if (selectedUrlStatus && urlsResult[selectedUrlStatus]) {
      const { responseTimes } = urlsResult[selectedUrlStatus];
      console.log(urlsResult)
      const labels = responseTimes.map((_, index) => `Request ${index + 1}`);
      setSelectedUrlChartData({
        labels,
        datasets: [
          {
            label: "Response Time",
            data: responseTimes,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ],
      });
    }
  }, [urlsResult, selectedUrlStatus]);

  /**
   * Effect: Generate Shareable Link
   * Creates a Base64-encoded shareable link containing the current URLs configuration.
   * - Encodes the `urls` state and the auto-start flag into a JSON object.
   * - Updates the `link` state with the full URL containing the encoded data.
   */
  useEffect(() => {
    const data = { urls: Object.values(urls), is_auto_start: true };
    const base64Data = encodeToBase64(data);
    setLink(`${window.location.origin}/multi-uptime-checker?data=${base64Data}`);
  }, [urls]);

  /**
   * Effect: Automatic Update URL Query
   * Synchronizes the application's URL query string with the current `urls` configuration.
   * - Encodes the `urls` state and the auto-start flag.
   * - Navigates to the updated query string using React Router's `navigate` function.
   */
  useEffect(() => {
    if (isParameter.current) {
      const base64Data = encodeToBase64({ urls: Object.values(urls), is_auto_start: true });
      navigate(`/multi-uptime-checker?data=${base64Data}`, { replace: true });
    };
  }, [urls, isChecking, navigate]);

  /**
   * Effect: Initialize from Query Parameters
   * Parses the application's query parameters on initial load.
   * - Decodes the `data` parameter if present.
   * - Updates the `urls` state with the decoded URLs configuration.
   * - Sets `isChecking` to `true` if the auto-start flag is enabled.
   * - Displays alerts for shared content or invalid configurations.
   * 
   * Notes:
   * - If `data` is present, the `isParameter` flag is activated.
   * - Disables certain features (e.g., saving to DB) when `isParameter` is active.
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const data = queryParams.get("data");
    if (data) {
      isParameter.current = true;
      alert("Shared content detected. Audio will not function until you interact with the UI, URLs will not be saved.");

      const decodedData = decodeFromBase64(data);
      if (decodedData && decodedData.urls) {
        const formattedUrls: { [key: string]: UrlData } = Array.isArray(decodedData.urls)
          ? decodedData.urls.reduce((acc, urlData: UrlData) => {
            acc[urlData.id] = urlData;
            return acc;
          }, {} as { [key: string]: UrlData })
          : (decodedData.urls as { [key: string]: UrlData });

        const hasEmptyFields = Object.values(formattedUrls).some(
          (urlData) => !urlData.id || !urlData.url || !urlData.method || !urlData.name
        );

        setUrls(formattedUrls);

        if (hasEmptyFields) {
          alert("The URL cannot be empty!");
          return;
        };

        if (decodedData.is_auto_start) {
          setIsChecking(true);
        };
      };
    }
  }, []);

  const renderSettingsView = () => (
    <div className="">
      <div className="grid grid-cols-1 gap-2">
        <div className="flex items-center space-x-2 text-white mb-2">
          <span className="w-1/6">Method</span>
          <span className="w-2/4">URL</span>
          <span className="w-1/6 text-left ml-4">Name</span>
        </div>
        {Object.values(urls).map(({ id, url, method, name }) => (
          <div key={id} className="flex items-center space-x-2 bg-gray-700 p-2 rounded">
            <select
              className="select select-bordered w-1/6"
              value={method}
              onChange={(e) => handleUpdateUrl(id, { method: e.target.value })}
              disabled={isChecking}
            >
              <option value="GET">GET</option>
              <option value="HEAD">HEAD</option>
              <option value="POST">POST</option>
            </select>
            <input
              type="text"
              value={url}
              placeholder="Enter URL here..."
              className="w-2/4 input input-bordered"
              onChange={(e) => handleUpdateUrl(id, { url: e.target.value })}
              disabled={isChecking}
            />
            <input
              type="text"
              value={name}
              placeholder="Enter API name..."
              className="input input-bordered w-1/6"
              onChange={(e) => handleUpdateUrl(id, { name: e.target.value })}
              disabled={isChecking}
            />
            <button className="p-2 rounded ml-2 bg-gray-800" onClick={() => handleDeleteUrl(id)} disabled={isChecking}>
              <img src="/icons/delete.svg" alt="Delete" className="w-6 h-6" />
            </button>
          </div>
        ))}
      </div>
      <div className="mt-4 flex items-center">
        <button className="btn btn-neutral" onClick={handleAddUrl} disabled={isChecking}>
          Add New URL
        </button>
        <button className={`${isChecking ? "btn-error" : "btn-primary"} btn ml-2`} onClick={handleStartStop}>
          {isChecking ? "Stop Checking" : "Start Checking"}
        </button>
      </div>
    </div>
  );

  const renderCopyUrlView = () => (
    <div className="">
      <textarea className="textarea textarea-bordered w-full h-32" value={link} readOnly />
      <div className="flex items-center justify-start mt-4">
        <button className="btn" onClick={() => navigator.clipboard.writeText(link)}>
          Copy URL
        </button>
        <p className="text-white ml-6">You can directly access the address to restore the dashboard</p>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-800">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold">Multi Uptime Checker</div>
        <div className="grid grid-cols-3 gap-4 mt-3">
          {Object.values(urlsResult).map(({ id, url, method, name, responseTimes, stats, status }) => {
            const lastResponseTime = responseTimes[responseTimes.length - 1];

            // Gray: Default background
            let bgColor = 'bg-gray-700';

            // Determine the background color based on HTTP status and response time.
            if (status === null || status >= 400) {
              bgColor = 'bg-red-700'; // Red: Error or no status available.
            } else if (lastResponseTime > 300 || (status >= 300 && status < 400) ) {
              bgColor = 'bg-yellow-500'; // Yellow: Slow response or redirect status.
            } else {
              bgColor = 'bg-green-700'; // Green: Healthy response.
            }
            return (
              <div
                key={id}
                className={`p-4 rounded shadow ${bgColor}`}
                onClick={() => openChartModal(id)}
              >
                <h2 className="font-bold text-white truncate overflow-hidden whitespace-nowrap">
                  {name} ({status})
                </h2>
                <p className="text-2xl text-white truncate overflow-hidden whitespace-nowrap">{lastResponseTime} ms</p>
                <p className="text-gray-400 truncate overflow-hidden whitespace-nowrap">
                  {method} {url}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mt-3">
          <div role="tablist" className="tabs tabs-lifted w-full">
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Settings"
              defaultChecked
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderSettingsView()}
              <div className="mt-4 border-t border-gray-700 pt-4">
                Interval (ms):
                <input
                  type="text"
                  value={inputInterval}
                  placeholder="Example (5000)"
                  className="input input-bordered w-1/6 ml-2 input-sm"
                  onChange={(e) => handleInterval(e.target.value)}
                  disabled={isChecking}
                />
              </div>
            </div>
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Copy URL"
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderCopyUrlView()}
            </div>
          </div>
        </div>
      </div>
      {isChartModalOpen && selectedUrlChartData && (
        <div className="modal modal-open">
          <div className="modal-box max-w-3xl">
            <div className="flex flex-col items-center">
              <div className="w-full ">
                <Line
                  data={selectedUrlChartData}
                  options={options}
                />
                <div className="grid grid-cols-3 gap-10 mt-6 justify-between text-center">
                  {selectedUrlStatus && urlsResult[selectedUrlStatus] ? (
                    <>
                      <div>
                        <p className="text-2xl font-bold text-white">
                          {urlsResult[selectedUrlStatus].stats.min}ms
                        </p>
                        <p className="text-sm text-gray-500">Min. response time</p>
                      </div>
                      <div>
                        <p className="text-2xl font-bold text-white">
                          {urlsResult[selectedUrlStatus].stats.avg}ms
                        </p>
                        <p className="text-sm text-gray-500">Avg. response time</p>
                      </div>
                      <div>
                        <p className="text-2xl font-bold text-white">
                          {urlsResult[selectedUrlStatus].stats.max}ms
                        </p>
                        <p className="text-sm text-gray-500">Max. response time</p>
                      </div>
                    </>
                  ) : (
                    <p className="text-white text-center col-span-3">No data available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-action">
              <button
                className="btn btn-error"
                onClick={closeChartModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiUptimeChecker;
