import { useState, ChangeEvent, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import base64Image from "../assets/json/base64Image.json";

export default function Base64ImageConverter() {
  const [base64String, setBase64String] = useState("");
  const [convertedImg, setConvertedImg] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageToBase64, setIsImageToBase64] = useState(false);
  const [isToggledBackground, setToggledBackground] = useState(false);
  const [outputType, setOutputType] = useState("Raw String");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setBase64String(value);
  };

  const convertBase64ToImage = useCallback(() => {
    setConvertedImg(`data:image/png;base64,${base64String}`);
  }, [base64String]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setBase64String((reader.result as string).split(",")[1]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCopyImage = async () => {
    try {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const img = new Image();
      img.src = convertedImg;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);

        canvas.toBlob((blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]);
          toast.success("Image copied to clipboard");
        }, "image/png");
      };
    } catch (error) {
      console.error("Clipboard error: " + error);
    }
  };

  const handleCopyBase64 = () => {
    if (base64String.trim() === "") {
      toast.error("Fill the input", {
        duration: 1500, // in ms
      });
      return;
    }

    navigator.clipboard.writeText(base64String);

    toast.success("Copied to Clipboard", {
      duration: 1500, // in ms
    });
  };

  const handleClipboard64 = async () => {
    try {
      const clipBoard = await navigator.clipboard.readText();
      setBase64String(clipBoard);
      toast.success("Paste value from Clipboard");
    } catch (error) {
      console.error("Hash error: " + error);
    }
  };

  const handleSample = () => {
    setBase64String(base64Image[0]);
  };

  const handleClear = () => {
    setBase64String("");
    setConvertedImg("");
    setSelectedImage(null);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = convertedImg;
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleMode = () => {
    setIsImageToBase64((prevMode) => !prevMode);
    setBase64String("");
    setSelectedImage(null);
  };

  useEffect(() => {
    const updateBase64String = () => {
      if (selectedImage) {
        const rawData = selectedImage.split(",")[1];
        switch (outputType) {
          case "Raw String":
            setBase64String(rawData);
            break;
          case "Data URL":
            setBase64String(`data:image/png;base64,${rawData}`);
            break;
          case "CSS Attribute":
            setBase64String(`url('data:image/png;base64,${rawData}')`);
            break;
          default:
            setBase64String(rawData);
        }
      }
    };

    updateBase64String();
    convertBase64ToImage();
  }, [base64String, convertBase64ToImage, outputType, selectedImage]);

  return (
    <div className="w-full px-6 my-4">
      <div className="w-full flex items-center justify-between font-bold mb-3">
        BASE64 Image Encode/Decode
        <button className="btn btn-xs mr-1" onClick={toggleMode}>
          Switch Mode
        </button>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <div className="flex items-center justify-between pb-2">
            <div className="flex gap-1 items-center">
              {isImageToBase64 ? (
                <>
                  Image:
                  <div className="ml-3">
                    <label htmlFor="input-image" className="btn btn-xs mr-1">
                      Load file...
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      id="input-image"
                      className="absolute top-full hidden"
                    />
                    <button className={`btn btn-xs mr-1 ${isToggledBackground ? "grayscale" : ''}`} onClick={() => setToggledBackground(!isToggledBackground)}>
                     💡
                    </button>
                    <button className="btn btn-xs mr-1" onClick={handleClear}>
                      Clear
                    </button>
                  </div>
                </>
              ) : (
                <>
                  String:
                  <div className="ml-3">
                    <button
                      className="btn btn-xs mr-1"
                      onClick={handleClipboard64}
                    >
                      Clipboard
                    </button>
                    <button className="btn btn-xs mr-1" onClick={handleSample}>
                      Sample
                    </button>
                    <button className="btn btn-xs mr-1" onClick={handleClear}>
                      Clear
                    </button>
                  </div>
                  <button className="btn btn-xs" onClick={handleCopyBase64}>
                    Copy 📋
                  </button>
                </>
              )}
            </div>
          </div>

          {isImageToBase64 ? (
            <>
              <div className={`${isToggledBackground ? 'bg-slate-200' : 'bg-gray-900'} w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white flex justify-center items-center relative overflow-hidden`}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  id="input-image"
                  className="absolute top-full hidden"
                />
                {selectedImage && (
                  <img src={selectedImage} alt="Selected" className="w-1/2" />
                )}
              </div>
            </>
          ) : (
            <>
              <textarea
                className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white"
                placeholder="Enter text here"
                value={base64String}
                onChange={handleInputChange}
              />
              <div className="flex items-center justify-end">
                <small>{base64String.length.toLocaleString()} bytes</small>
              </div>
            </>
          )}
        </div>

        <div>
          <div className="flex items-center pb-2">
            <div className="flex gap-1 items-center justify-between w-full">
              {isImageToBase64 ? (
                <>
                  String:
                  <button className="btn btn-xs" onClick={handleCopyBase64}>
                    Copy 📋
                  </button>
                </>
              ) : (
                <>
                  Image:
                  <div>
                    <button className={`btn btn-xs mr-1 ${isToggledBackground ? "grayscale" : ''}`} onClick={() => setToggledBackground(!isToggledBackground)}>
                     💡
                    </button>
                    <button
                      className="btn btn-xs mr-1"
                      onClick={handleDownload}
                    >
                      Save
                    </button>
                    <button className="btn btn-xs" onClick={handleCopyImage}>
                      Copy 📋
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {isImageToBase64 ? (
            <>
              <textarea
                className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white"
                value={base64String}
                readOnly
              />
              <div className="flex items-center justify-between">
                <div className="flex flex-row gap-1.5">
                  <button
                    className="btn btn-xs"
                    onClick={() => setOutputType("Raw String")}
                  >
                    Raw string
                  </button>
                  <button
                    className="btn btn-xs"
                    onClick={() => setOutputType("Data URL")}
                  >
                    Data URL
                  </button>
                  <button
                    className="btn btn-xs"
                    onClick={() => setOutputType("CSS Attribute")}
                  >
                    CSS Attribute
                  </button>
                </div>
                <small>{base64String.length.toLocaleString()} bytes</small>
              </div>
            </>
          ) : (
            <div className={`${isToggledBackground ? 'bg-slate-200' : 'bg-gray-900'} w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white flex justify-center items-center relative overflow-hidden`}>
              {convertedImg === "" ? null : (
                <img src={convertedImg} alt="" className="w-1/2" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
