import React, { useState, useEffect, useCallback } from "react";
import { openDB } from "idb";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast from "react-hot-toast";
import JSZip from "jszip";

const databaseName = "devtool";
const tableName = "config";
const key = "notepad";
const TrashIcon = '/icons/trash-bin.svg';
const EditIcon = '/icons/pencil.svg';

function Notepad() {
  const [db, setDb] = useState<any>(null);
  const [files, setFiles] = useState<{ [id: string]: any }>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<string | null>(null);
  const [text, setText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState<string | null>(null);
  const [editingFileIndex, setEditingFileIndex] = useState<string | null>(null);
  let [editedTitle, setEditedTitle] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
      ['clean']
    ],
  };

  const selectFile = useCallback((index: string, content: string) => {
    setSelectedFileIndex(index);
    setText(content);
    setHasUnsavedChanges(false);
  }, []);

  const saveCurrentFile = useCallback(async () => {
    if (selectedFileIndex === null || db == null) return;

    const updatedFiles = {
      ...files,
      [selectedFileIndex]: { ...files[selectedFileIndex], content: text },
    };

    await db.put(tableName, { key, data: updatedFiles });

    setFiles(updatedFiles);
    setHasUnsavedChanges(false);
  }, [selectedFileIndex, db, files, text]);

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: 'key' });
          }
        },
      });

      const savedFiles = await database.get(tableName, key);

      if (savedFiles) {
        setFiles(savedFiles.data || {});
        if (Object.keys(savedFiles.data).length > 0) {
          selectFile(Object.keys(savedFiles.data)[0], savedFiles.data[Object.keys(savedFiles.data)[0]].content)
        }
      }
      setDb(database);
    };
    initDB();
  }, [selectFile]);

  useEffect(() => {
    const saveChanges = async () => {
      if (hasUnsavedChanges) {
        await saveCurrentFile();
      }
    };

    saveChanges();
  }, [text, hasUnsavedChanges, saveCurrentFile]);

  const handleAddFile = async () => {
    if (db === null) return;

    const today = new Date();
    const id = uuidv4();
    const newFile = {
      id: id,
      name: generateFileName(),
      content: "",
      created: today.toISOString(),
    };

    const updatedFiles = {
      [id]: newFile,
      ...files,
    };

    await db.put(tableName, { key, data: updatedFiles });
    setFiles(updatedFiles);
    selectFile(id, newFile.content);
  };

  const handleTextChange = async (content) => {
    setText(content);
    setHasUnsavedChanges(true);
  };

  const handleDeleteFile = async () => {
    if (fileToDeleteIndex === null) return;

    const updatedFiles = { ...files };
    delete updatedFiles[fileToDeleteIndex];

    await db.delete(tableName, fileToDeleteIndex);

    setFiles(updatedFiles);
    setIsModalOpen(false);

    if (fileToDeleteIndex === selectedFileIndex) {
      setSelectedFileIndex(null);
      setText("");
    }

    await db.put(tableName, { key, data: updatedFiles });
  };

  const handleEditSave = async () => {
    if (editingFileIndex === null) return;

    if (handleCheckFileName(editedTitle)) {
      toast.error("File name already used!");
      return;
    };

    if (!editedTitle.endsWith(".md")) {
      editedTitle += ".md";
    };

    const updatedFiles = {
      ...files,
      [editingFileIndex]: { ...files[editingFileIndex], name: editedTitle },
    };

    setFiles(updatedFiles);
    setEditingFileIndex(null);
    setEditedTitle("");
    setIsEditModalOpen(false);
    await db.put(tableName, { key, data: updatedFiles });
  };

  const handleCheckFileName = (name) => {
    const fileNames = Object.values(files).map(file => file.name);

    const isDuplicate = fileNames.includes(name);

    return isDuplicate;
  };

  const generateFileName = () => {
    const today = new Date();
    let baseName = `${today.toISOString().split("T")[0].replace(/-/g, "")}_untitled`;
    let fileIndex = Object.keys(files).length + 1;
    let newFileName = `${baseName}_${fileIndex}.md`;

    while (handleCheckFileName(newFileName)) {
      fileIndex += 1;
      newFileName = `${baseName}_${fileIndex}.md`;
    }

    return newFileName;
  };

  const handleExportAllNotes = async () => {
    const zip = new JSZip();
    console.log("Tes");

    Object.values(files).forEach((file) => {
      const { name, content } = file;

      let cleanedContent = content
        .replace(/<p><br><\/p>/g, "<br>")
        .replace(/<p class="ql-align-justify">\s*<br\s*\/?>\s*<\/p>/gi, "<br>")
        .replace(/<\/li>/gi, '\n')
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/<[^>]+>/g, '');

      zip.file(name, cleanedContent);
    });

    zip.generateAsync({ type: "blob" }).then((zipBlob) => {
      const url = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "all_notes.zip";
      link.click();

      URL.revokeObjectURL(url);
    });
  };

  const handleExportNote = () => {
    if (selectedFileIndex !== null && files[selectedFileIndex]) {
      let fileName = files[selectedFileIndex].name;
      if (!fileName.endsWith(".md")) {
        fileName += ".md";
      }

      let cleanedContent = text.replace(/<p><br><\/p>/g, "<br>")
        .replace(/<p class="ql-align-justify">\s*<br\s*\/?>\s*<\/p>/gi, "<br>");

      cleanedContent = cleanedContent
        .replace(/<\/li>/gi, '\n')
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/<[^>]+>/g, '');

      const blob = new Blob([cleanedContent], { type: "text/markdown" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      link.click();

      URL.revokeObjectURL(link.href);
    }
  };

  return (
    <div className="mx-6 my-4">
      {/* Title */}
      <div className="flex items-center mb-3">
        <div className="title flex items-center font-bold space-x-2">
          <span>Notepad</span>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        {/* Left Side */}
        <div className="w-1/3 flex flex-col items-start">
          <div className="flex justify-start items-center mb-4 gap-2">
            <button className="btn btn-xs" onClick={handleExportAllNotes}>Export All</button>
            <button className="btn btn-xs" onClick={handleAddFile}>Add Note</button>
          </div>
          <ul className="bg-gray-900 w-full border border-gray-700 rounded-md overflow-y-auto min-h-[78.5vh] max-h-[78.5vh] flex-1">
            {Object.keys(files).map((fileindex) => (
              <li
                key={fileindex}
                className={`p-2 cursor-pointer flex justify-between items-center ${fileindex === selectedFileIndex ? "bg-gray-700" : "bg-gray-800"}`}
                onClick={() => selectFile(fileindex, files[fileindex].content)}
              >
                <span>{files[fileindex].name}</span>
                <div className="flex items-center">
                  <button className="ml-2 bg-blue-500 text-white p-1 rounded" onClick={(e) => {
                    e.stopPropagation();
                    setEditingFileIndex(fileindex);
                    setEditedTitle(files[fileindex].name);
                    setIsEditModalOpen(true);
                  }}>
                    <img src={EditIcon} alt="Edit Icon" className="w-4 h-4" />
                  </button>
                  <button className="ml-2 bg-red-500 text-white p-1 rounded" onClick={(e) => {
                    e.stopPropagation();
                    setFileToDeleteIndex(fileindex);
                    setIsModalOpen(true);
                  }}>
                    <img src={TrashIcon} alt="Trash Bin" className="w-4 h-4" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Side */}
        <div className="w-2/3 ">
          <div className="flex justify-end items-center mb-4">
            <button className="btn btn-xs mx-1" onClick={handleExportNote}>Export</button>
          </div>
          <ReactQuill
            value={text}
            onChange={handleTextChange}
            theme="snow"
            modules={modules}
            readOnly={selectedFileIndex === null}
            placeholder="Write your notes here..."
            className="custom-quill custom-quill-container w-full min-h-[78.5vh] max-h-[78.5vh] flex-1 text-white"
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Confirm Deletion</h3>
              <p className="py-4">Are you sure you want to delete this note?</p>
              <div className="modal-action">
                <button className="btn" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </button>
                <button className="btn btn-error" onClick={handleDeleteFile}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Edit Note Title</h3>
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="input input-bordered w-full mt-4"
              />
              <div className="modal-action">
                <button className="btn" onClick={() => setIsEditModalOpen(false)}>
                  Cancel
                </button>
                <button className="btn btn-success" onClick={handleEditSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .ql-toolbar {
          background-color: #374151;
          border-radius: 8px;
          margin-bottom: 10px;
        }

        .ql-toolbar .ql-picker-label,
        .ql-toolbar .ql-picker-item,
        .ql-toolbar button {
          color: white;
        }

        .ql-toolbar.ql-snow {
          border: 1px solid #374151;
        }

        .ql-snow.ql-toolbar button:hover,
        .ql-snow .ql-toolbar button:hover,
        .ql-snow.ql-toolbar button:focus,
        .ql-snow .ql-toolbar button:focus,
        .ql-snow.ql-toolbar button.ql-active,
        .ql-snow .ql-toolbar button.ql-active,
        .ql-snow.ql-toolbar .ql-picker-label:hover,
        .ql-snow .ql-toolbar .ql-picker-label:hover,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active,
        .ql-snow.ql-toolbar .ql-picker-item:hover,
        .ql-snow .ql-toolbar .ql-picker-item:hover,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
          color: #60a5fa; 
        }

        .ql-snow.ql-toolbar button:hover .ql-stroke,
        .ql-snow .ql-toolbar button:hover .ql-stroke,
        .ql-snow.ql-toolbar button:focus .ql-stroke,
        .ql-snow .ql-toolbar button:focus .ql-stroke,
        .ql-snow.ql-toolbar button.ql-active .ql-stroke,
        .ql-snow .ql-toolbar button.ql-active .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
        .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
        .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
        .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
        .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
        stroke: #60a5fa;
        }

        .ql-toolbar .ql-picker-item.ql-selected,
        .ql-toolbar button.ql-active {
          color: #60a5fa;
        }

        .ql-toolbar .ql-stroke {
          stroke: white;
        }
        .ql-toolbar .ql-fill {
          fill: white;
        }

        .ql-container {
          background-color: transparent;
          flex-grow: 1;
          overflow-y: auto;
        }

        .ql-container.ql-snow {
          border: none;

        }

        .ql-editor {
          background-color: #111827;
          color: white;
          border-radius: 8px;
          overflow-y: scroll;
          border: 1px solid #374151;
        }

        .ql-picker {
          background-color: #374151;
        }
        .ql-picker-options {
          background-color: #374151;
        }
        .ql-picker-item {
          background-color: #374151;
        }

        .ql-picker-item:hover {
          background-color: #4a5568;
          color: #60a5fa;
        }

        .ql-picker-item.ql-selected {
          background-color: #4a5568;
          color: #60a5fa;
        }

        .ql-snow .ql-picker-options {
          background-color: inherit;
        }

        .custom-quill .ql-editor.ql-blank::before {
          color: #999;
        }

        .custom-quill {
          display: flex;
          flex-direction: column;
          height: 100%;
          border: none;
          border-radius: 8px;
        }
        `
      }</style>
    </div>
  );
}

export default Notepad;