import { useState } from "react";
import toast from "react-hot-toast";

export function CopyButton({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = () => {
    copyToClipboard(text);


    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <button className="h-max btn btn-xs"
      onClick={handleClickCopy}
    >
      {isCopied ? "✅" : "📋"}
    </button>
  );
}

export default CopyButton;

export const copyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);

  toast.success('Copied to Clipboard', {
    duration: 1500, // in ms
  });
};
