import CopyButton from "../components/CopyButton";
import { useEffect, useState } from "react";

import sampleJson from "../assets/json/line_sort.json";

const order = [
  {
    title: "A → Z [Text]",
    order: "text-asc",
  },
  {
    title: "Z → A [Text]",
    order: "text-desc",
  },
  {
    title: "0 → 9 [Number]",
    order: "number-asc",
  },
  {
    title: "9 → 0 [Number]",
    order: "number-desc",
  },
];

export default function LineSort() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [withDuplicate, setWithDuplicate] = useState(1);
  const [sortOrder, setSortOrder] = useState("text-asc");
  const [error, setError] = useState("");

  useEffect(() => {
    if (input.trim() === "") {
      setOutput("");
      setError("");
      return;
    }

    const validate = (text: string[], type: string) => {
      const containsNumbers = text.some((line) => /\d/.test(line));
      const containsAlphabets = text.some((line) => /[a-zA-Z]/.test(line));

      if (type === "text" && containsNumbers) {
        setError("Can't contain number");
        return false;
      }

      if (type === "number" && containsAlphabets) {
        setError("Can't contain char");
        return false;
      }

      setError("");
      return true;
    };

    const sortLines = () => {
      let lines = input.split("\n").filter((line) => line.trim() !== "");
      const orderSplit = sortOrder.split("-");
      const type = orderSplit[0];
      const order = orderSplit[1];

      if (!validate(lines, type)) {
        return;
      }

      if (!withDuplicate) {
        lines = Array.from(new Set(lines));
      }

      if (type === "text") {
        lines.sort();
        if (order === "desc") {
          lines.reverse();
        }
      } else {
        lines.sort((a, b) =>
          order === "asc" ? Number(a) - Number(b) : Number(b) - Number(a)
        );
      }

      setOutput(lines.join("\n"));
    };

    sortLines();
  }, [input, withDuplicate, sortOrder]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInput(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  const handleClear = () => {
    setInput("");
  };

  const handleSampleJSON = () => {
    if (sortOrder.split("-")[0] === "text") {
      setInput(sampleJson[0]);
    } else {
      setInput(sampleJson[1]);
    }
  };

  return (
    <div className="h-[90vh] flex">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">
          Line Sort/Dedupe
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Input:</div>
              <div className="inline-block ml-3">
                <button
                  className="btn btn-xs mx-1"
                  onClick={handlePasteFromClipboard}
                >
                  Clipboard
                </button>
                <button className="btn btn-xs mx-1" onClick={handleSampleJSON}>
                  Sample
                </button>
                <button className="btn btn-xs mx-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
            <textarea
              className="resize-none overflow-y-scroll h-[83.5vh] bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 p-3"
              placeholder="Enter text here"
              value={input}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <div className="flex justify-between mb-2">
              <div className="mb-2">Output:</div>
              <p className="text-red-500 text-xs self-center text-center">{error}</p>
              <div className="flex items-center mb-1 gap-2">
                <select
                  className="select select-bordered select-xs"
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  {order.map((data, index) => (
                    <option key={index} value={data.order}>
                      {data.title}
                    </option>
                  ))}
                </select>
                <select
                  className="select select-bordered select-xs"
                  value={withDuplicate}
                  onChange={(e) => setWithDuplicate(Number(e.target.value))}
                >
                  {["Without Duplicate", "With Duplicate"].map(
                    (title, index) => (
                      <option key={index} value={index}>
                        {title}
                      </option>
                    )
                  )}
                </select>
                <CopyButton text={output} />
              </div>
            </div>

            <textarea
              className="resize-none overflow-y-scroll h-[83.5vh] bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 p-3"
              value={output}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}
