import ReactCodeMirror, { EditorView } from "@uiw/react-codemirror";
import { useState, useEffect, useCallback, useRef } from "react";
import { jsonLanguage } from "@codemirror/lang-json";
import CopyButton from "../components/CopyButton";
import { dracula } from "@uiw/codemirror-theme-dracula";
import moment from "moment";
import toast from "react-hot-toast";

const LogViewer = () => {
  const [logData, setLogData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [expandedStates, setExpandedStates] = useState({});
  const fetchingRef = useRef(false);
  const prevDataRef = useRef(null);
  const host = "http://127.0.0.1:19999";

  const parseLogs = useCallback((data) => {
    const timestampPattern = /^\[\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}\]/;

    // First, split and collect all logs with their indices
    const logChunks = data.split('\n').reduce((chunks, line, index) => {
      if (timestampPattern.test(line)) {
        // Store both the content and original index
        chunks.push({ content: line, index });
      } else if (chunks.length > 0) {
        chunks[chunks.length - 1].content += '\n' + line;
      }
      return chunks;
    }, []);

    return logChunks
      .filter(chunk => chunk.content.trim() !== '')
      .map(chunk => {
        const basicMatch = chunk.content.match(/^\[(.*?)\]\s(\w+.\w+):\s(.*)$/s);

        if (!basicMatch) return null;

        const [, timestamp, level, remainingContent] = basicMatch;

        const jsonStartIndex = remainingContent.indexOf('{');
        let message, context;

        if (jsonStartIndex !== -1) {
          message = remainingContent.substring(0, jsonStartIndex).trim();

          try {
            const jsonPart = remainingContent.substring(jsonStartIndex);
            context = JSON.parse(jsonPart);
          } catch (e) {
            context = remainingContent;
            message = remainingContent.length > 100
              ? remainingContent.substring(0, 100) + '...'
              : remainingContent;
          }
        } else {
          message = remainingContent;
          if (message.length > 100) {
            context = message;
            message = message.substring(0, 100) + '...';
          }
        }

        return {
          id: chunk.index, // Use the original index as a unique identifier
          createdAt: timestamp,
          level: level,
          message: message,
          context: context,
          expanded: false
        };
      }).filter(log => log !== null);
  }, []);

  const fetchLogData = useCallback(async () => {
    if (fetchingRef.current) return;

    fetchingRef.current = true;
    try {
      const response = await fetch(host + "/logs");
      if (response.ok) {
        const data = await response.text();
        const parsedLogs = parseLogs(data);

        const prevData = prevDataRef.current;
        if (JSON.stringify(parsedLogs) !== JSON.stringify(prevData)) {
          const sortedLogs = parsedLogs.sort((a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );

          setLogData(sortedLogs.map(log => {
            const logKey = `${log.createdAt}-${log.level}-${log.message.substring(0, 50)}`;
            return {
              ...log,
              expanded: expandedStates[logKey] || false
            };
          }));

          setFilteredLogs(sortedLogs.map(log => {
            const logKey = `${log.createdAt}-${log.level}-${log.message.substring(0, 50)}`;
            return {
              ...log,
              expanded: expandedStates[logKey] || false
            };
          }));

          prevDataRef.current = sortedLogs;
        }
      } else {
        console.error("Failed to fetch log data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      fetchingRef.current = false;
    }
  }, [parseLogs, expandedStates]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term === "") {
      setFilteredLogs(logData);
    } else {
      filterLogs(term, selectedLevel);
    }
  };

  const handleClear = async () => {
    try {
      const response = await fetch(host + "/clear", {
        method: "DELETE",
      });
      if (response.ok) {
        toast.success('Log cleared', {
          duration: 1500,
        });
      } else {
        console.error("Failed to clear log data:", response.status);
      }
    } catch (error) {
      console.error("Error clearing log data:", error);
    }
  }

  const handleLevelChange = (event) => {
    const level = event.target.value;
    setSelectedLevel(level);
    filterLogs(searchTerm, level);
  };

  const filterLogs = useCallback((search, level) => {
    setFilteredLogs(currentLogData =>
      currentLogData.filter(log => {
        const matchesSearch = log.message.toLowerCase().includes(search.toLowerCase());
        const matchesLevel = level ? log.level.toLowerCase().includes(level.toLowerCase()) : true;
        return matchesSearch && matchesLevel;
      })
    );
  }, []);

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm || searchTerm.trim() === '') {
      return text;
    }

    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');

    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="bg-yellow-300 text-black font-medium px-0.5 rounded">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  const toggleExpand = (index, event) => {
    event.stopPropagation();

    const log = filteredLogs[index];
    const logKey = `log-${log.id}`; // Use the unique id

    setExpandedStates(prev => ({
      ...prev,
      [logKey]: !prev[logKey]
    }));

    setFilteredLogs(prevLogs => {
      const newLogs = [...prevLogs];
      newLogs[index] = {
        ...newLogs[index],
        expanded: !newLogs[index].expanded
      };
      return newLogs;
    });
  };

  const fetchLogDataRef = useRef(fetchLogData);

  useEffect(() => {
    fetchLogDataRef.current = fetchLogData;
  }, [fetchLogData]);

  useEffect(() => {
    fetchLogDataRef.current();

    const interval = setInterval(() => {
      fetchLogDataRef.current();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    filterLogs(searchTerm, selectedLevel);
  }, [searchTerm, selectedLevel, logData, filterLogs]);

  const cli = "curl https://devtool.internal.taksu.tech/lv.txt -o lv && php -S 127.0.0.1:19999 lv";

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">
          Log Viewer
        </div>

        <div className="relative w-full h-full mt-5 text-white">
          <div className="flex justify-between items-center mb-4">
            <div className="flex gap-2 items-center">
              <label className="input input-bordered flex items-center gap-2">
                <input
                  type="text"
                  className="grow w-[300px]"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="h-4 w-4 opacity-70">
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd" />
                </svg>
              </label>
              <select
                value={selectedLevel}
                onChange={handleLevelChange}
                className="select select-bordered w-[200px]"
              >
                <option value="">All Levels</option>
                <option value="DEBUG">DEBUG</option>
                <option value="INFO">INFO</option>
                <option value="WARN">WARN</option>
                <option value="ERROR">ERROR</option>
                <option value="NOTICE">NOTICE</option>
                <option value="ALERT">ALERT</option>
                <option value="CRITICAL">CRITICAL</option>
                <option value="EMERGENCY">EMERGENCY</option>
                <option value="WARNING">WARNING</option>
              </select>
              <div className="flex items-center ml-2">
                {filteredLogs.length > 0 ? (
                  <span className="text-sm text-gray-300">
                    {filteredLogs.length} log{filteredLogs.length > 1 ? 's' : ''}
                  </span>
                ) : (
                  <span className="text-sm text-gray-300">No logs</span>
                )}
              </div>
            </div>
            <div>
              <button className="btn btn-error" onClick={handleClear}>Clear</button>
            </div>
          </div>
          <div className="overflow-y-auto border border-gray-700 rounded p-4">
            {filteredLogs.length === 0 && (
              <div className="flex flex-col items-center text-gray-400 py-10">
                <div className="text-center">Run the CLI command to get started</div>
                <div className="p-3 flex items-center">
                  <div className="mr-3 bg-gray-900 px-4 py-1 rounded-lg">{cli}</div>
                  <CopyButton text={cli} />
                </div>
                <div className="mr-3 px-4 text-xs py-1 rounded-lg text-gray-600">
                  If keep failing, try to disable the adblocker.
                </div>
                <div className="px-4 text-xs rounded-lg text-gray-600">
                  You can add `lv` to your .gitignore, to avoid committing it to the repository.
                </div>
              </div>
            )}
            {filteredLogs.map((log, index) => {
              const logKey = `log-${log.id}`;
              return (
                <div
                  className="border border-gray-600 bg-gray-600 mb-2 px-2 rounded-lg py-2 hover:bg-gray-700 transition-colors cursor-pointer"
                  key={logKey}
                  onClick={(e) => toggleExpand(index, e)}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      {(() => {
                        const [env, level] = log.level.split('.');
                        let levelClass;

                        switch (level) {
                          case 'NOTICE':
                            levelClass = 'bg-purple-600';
                            break;
                          case 'ALERT':
                          case 'CRITICAL':
                          case 'EMERGENCY':
                          case 'ERROR':
                            levelClass = 'bg-red-600';
                            break;
                          case 'WARNING':
                            levelClass = 'bg-yellow-600';
                            break;
                          case 'DEBUG':
                            levelClass = 'bg-gray-400';
                            break;
                          case 'INFO':
                            levelClass = 'bg-blue-400';
                            break;
                          default:
                            levelClass = 'bg-green-600';
                            break;
                        }
                        return (
                          <>
                            <span className={`border border-gray-600 text-white px-4 py-1 rounded-lg text-sm mr-1 ${levelClass}`}>
                              {level}
                            </span>
                            <span className={`border border-gray-600 bg-gray-700 px-4 py-1 rounded-lg text-sm mr-3`}>
                              {env}
                            </span>
                            <div className="">
                              {highlightMatch(log.message, searchTerm)}
                            </div>
                          </>
                        );
                      })()}
                    </div>
                    <div className="text-sm mt-[4px] mr-2 text-gray-400">
                      {moment(log.createdAt).fromNow()}
                    </div>
                  </div>
                  {(expandedStates[logKey] && log.context) && (
                    <pre className="mt-2 rounded border border-gray-600">
                      <ReactCodeMirror
                        className="bg-gray-900 w-full border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500"
                        extensions={[EditorView.lineWrapping, jsonLanguage]}
                        value={JSON.stringify(log.context, null, 2)}
                        theme={dracula}
                      />
                    </pre>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogViewer;
