import axios from "axios";
import toast from "react-hot-toast";
import {copyToClipboard} from "../components/CopyButton";
import {useEffect, useRef, useState} from "react";

import CodeMirror, {EditorView} from "@uiw/react-codemirror";
import {typescriptLanguage} from "@codemirror/lang-javascript";
import {htmlLanguage} from "@codemirror/lang-html";
import {dracula} from "@uiw/codemirror-theme-dracula";
import WorkInProgressBadge from "../components/WorkInProgressBadge";

export default function MagicSimpleCoder() {
  const [inputText, setInputText] = useState("");
  const [model, setModel] = useState("groq-llama3-8b-8192");
  const [isLoading, setIsLoading] = useState(false);
  const [resultText, setResultText] = useState("");
  const [lang, setLang] = useState("tailwind-and-html");

  const [loadingTime, setLoadingTime] = useState(0);

  useEffect(() => {
    let timer;

    if (isLoading) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => prevTime + 100);
      }, 100);
    } else {
      setLoadingTime(0); // Reset loading time when loading is complete
      clearInterval(timer); // Clear interval when loading is complete
    }

    // Clear the interval when component unmounts or when loading is done
    return () => clearInterval(timer);
  }, [isLoading]); // Re-run effect whenever isLlmLoading changes

  useEffect(() => {
    if (typeof Storage !== "undefined") {
      localStorage.setItem("llm_execution_time", loadingTime.toString());
    }
  }, [loadingTime]);

  useEffect(() => {
    setResultText("");
  }, [lang, inputText]);

  const CodePreview = ({code}) => {
    const iframeRef = useRef(null);

    useEffect(() => {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      const extractReturnContent = (componentCode) => {
        const regex = /return\s*\(([\s\S]*?)\);/;
        const match = componentCode.match(regex);

        if (match && match[1]) {
          const jsxContent = match[1].trim();
          return jsxContent.replace(/className=/g, "class=");
        } else {
          console.error("Could not find return content.");
          return <div></div>;
        }
      };

      iframeDoc.open();
      iframeDoc.write(
        lang === "tailwind-and-html" && code.includes("<head>")
          ? code
          : `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Live Preview</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">
      </head>
      <body class="h-full w-full">
          ${extractReturnContent(code)}
      </body>
      </html>
    `
      );
      iframeDoc.close();
    }, [code]);

    return (
      <iframe
        ref={iframeRef}
        title="Live Preview"
        className="w-full h-full rounded-md bg-white"
      />
    );
  };

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error paste from clipboard: " + error);
    }
  };

  const handleSubmit = async (props: any) => {
    if (props.input === "") {
      toast.error("Input can't be null");
      return;
    }

    // Compose prompt
    setIsLoading(true);

    // Compose system instruction

    const fetchData = async () => {
      const customCommand =
        lang === "tailwind-and-html"
          ? "dont using react, using HTML and tailwind instead in 1 code from <!DOCTYPE html>"
          : "using react tsx and tailwind only, don't using function like map or etc, use return";
      try {
        const response = await axios.post(
          'https://groq1.huedaya.com/api/completion',
          {
            model: props.model.replace("groq-", ""),
            messages: [
              {
                content:
                  "You are AI assistant that very good at programming, " +
                  "your fluency level is expert, so do not explain code too much." +
                  "Do not include alternative answers. Do not explain the code, do not include comments." +
                  "Do not include texts like Here is the React component code: just code." +
                  "Write it in proper indention and clean code variable naming. Dont use third-party library",
                role: "system",
              },
              {
                content:
                  "using " +
                  lang.replace("-", " ") +
                  "\n" +
                  inputText +
                  "\n" +
                  customCommand,
                role: "user",
              },
            ],
            temperature: 0.2,
            max_tokens: 2048,
            top_p: 1,
            stream: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.choices.length > 0) {
          var trimmedResult = response.data.choices[0].message.content;
          if (trimmedResult.includes("```")) {
            const regex = /```[a-zA-Z]*\n([\s\S]*?)\n```/g;
            const match = regex.exec(trimmedResult);

            if (match) {
              trimmedResult = match[1].trim();
            } else {
              toast.error("Tidak dapat menemukan kode.");
            }
          } else {
            const regex = /<head[\s\S]*?<\/head>|<body[\s\S]*?<\/body>/g;
            const matches = trimmedResult.match(regex);

            if (matches) {
              trimmedResult = matches.join("\n").trim();
            } else {
              console.error("Tidak dapat menemukan kode.");
            }
          }
          setResultText(trimmedResult);
        } else {
          toast.error(response.data.error);
        }
        setIsLoading(false);

        // Load timer from local storage
        if (typeof Storage !== "undefined") {
          var llmExecutionTime = localStorage.getItem("llm_execution_time");
          toast.success(
            "Done in " + (parseInt(llmExecutionTime) / 1000).toFixed(1) + "s"
          );
        } else {
          toast.success("Done!");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.message);
        setIsLoading(false);
      }
    };
    fetchData();
  };

  return (
    <>
      <div className="h-screen flex">
        <div className="w-full mx-6 my-4">
          <div className="w-full flex items-center gap-3 font-bold mb-3">
            <div className="title">
              Magic Simple Coder
            </div>
            <WorkInProgressBadge/>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-0">
            <div className="mb-2">
              <div className="flex w-full justify-between">
                <div className="flex ml-2">
                  <div className="mb-2 mr-3">Input</div>
                  <button
                    className="btn btn-xs mx-1"
                    onClick={handlePasteFromClipboard}
                  >
                    Clipboard
                  </button>
                  <button
                    className="btn btn-xs mx-1"
                    disabled={isLoading}
                    onClick={() => {
                      handleSubmit({
                        model: model,
                        input: inputText,
                      });
                    }}
                  >
                    {isLoading ? (
                      <>Loading {(loadingTime / 1000).toFixed(1)}s</>
                    ) : (
                      <>Convert</>
                    )}
                  </button>
                </div>
                <div className="flex items-center h-fit">
                  <select
                    className="btn btn-xs mx-1 text-start"
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                  >
                    <option value="tailwind-and-tsx">Tailwind & TSX</option>
                    <option value="tailwind-and-html">Tailwind & HTML</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div>Code</div>
              <div className="flex items-center h-fit">
                <select
                  className="btn btn-xs mx-1 text-start"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value={"groq-llama3-70b-8192"}>
                    Groq: llama3-70b-8192
                  </option>
                  <option value={"groq-llama3-8b-8192"}>
                    Groq: llama3-8b-8192
                  </option>
                  <option value={"groq-gemma2-9b-it"}>
                    Groq: gemma2-9b-it
                  </option>
                  <option value={"groq-gemma-7b-it"}>Groq: gemma-7b-it</option>
                  <option value={"groq-mixtral-8x7b-32768"}>
                    Groq: mixtral-8x7b-32768
                  </option>
                </select>
              </div>
            </div>
            <div>
              <textarea
                className="resize-none bg-gray-900 w-full px-4 py-3 mb-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 h-[50vh] text-white"
                onChange={(e) => setInputText(e.target.value)}
                value={inputText}
              />
              <div className="mb-3">Preview</div>
            </div>
            <div className="mb-2">
              <CodeMirror
                className="bg-gray-900 mb-2 w-full border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 "
                extensions={[
                  EditorView.editable.of(false),
                  EditorView.lineWrapping,
                  lang === "tailwind-and-tsx"
                    ? typescriptLanguage
                    : htmlLanguage,
                ]}
                theme={dracula}
                value={resultText}
                height="49.5vh"
              />
              <div className="flex justify-end">
                <button
                  className="btn btn-xs mx-1"
                  onClick={() => {
                    copyToClipboard(resultText);
                  }}
                >
                  Copy Code
                </button>
              </div>
            </div>
          </div>
          <div className="bg-gray-900 w-full border border-gray-700 rounded-md h-[500px] text-white">
            {resultText && <CodePreview code={resultText} />}
          </div>
        </div>
      </div>
    </>
  );
}
