import { MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import { LatLngBoundsLiteral } from "leaflet";
import "leaflet/dist/leaflet.css";
import CopyButton from "../components/CopyButton";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import mqtt from "precompiled-mqtt";
import { openDB, IDBPDatabase } from 'idb';
import md5 from 'md5';
import L from 'leaflet';
import iconMarker from 'leaflet/dist/images/marker-icon.png';

const { XMLParser } = require("fast-xml-parser");

const customDivIcon = new L.DivIcon({
  className: "custom-marker-school",
  html: `
    <div class="flex flex-col items-center justify-center -mt-[20px]">
      <img src="${iconMarker}" alt="Marker" class="w-8 h-8 relative" />
      <div class="font-bold text-white mb-[6px] absolute"></div>
    </div>
  `
});

// Name of file only
const locationTemplatesName = [
  'loc1',
  'loc2',
];
const payloadTemplatesName = [
  'payload1',
];

// Start map on Singapore by default
const bounds: LatLngBoundsLiteral = [
  [1.1443, 103.596],
  [1.4835, 104.1],
];

const generateMD5 = (input: string): string => {
  return md5(input);
};

/**
 * Main function
 */
const LocationSimulator = () => {
  const [mqttHost, setMqttHost] = useState('');
  const [mqttClientID, setMqttClientID] = useState('');
  const [mqttUsername, setMqttUsername] = useState('');
  const [mqttPassword, setMqttPassword] = useState('');
  const [mqttChannel, setMqttChannel] = useState('');
  const [mqttPayload, setMqttPayload] = useState('');
  const [locationRoute, setLocationRoute] = useState([]);
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [payloadTemplates, setPayloadTemplates] = useState([]);
  const [gpxFileName, setGpxFileName] = useState('');
  const [currentHash, setCurrentHash] = useState('');
  const [gpxOptions, setGpxOptions] = useState([]);
  const [gpxData, setGpxData] = useState({});
  const [selectedPayloadTemplate, setSelectedPayloadTemplate] = useState('custom-payload');

  // Nama database dan store
  const dbName = 'devtool';
  const storeName = 'config';
  const appKey = 'location-simulator';

  const initDb = async (): Promise<IDBPDatabase<any>> => {
    let db: IDBPDatabase<any>;

    try {
      db = await openDB(dbName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: 'key' });
          }
        },
      });
    } catch (error) {
      console.error('Kesalahan saat membuka database:', error);
    }

    return db;
  };

  const loadConfigFromIndexedDB = useCallback(async () => {
    try {
      const db = await initDb();
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const data = await store.get(appKey);

      if (data) {
        const gpxEntries = Object.keys(data.data).map((hash) => ({
          hash,
          gpxFileName: data.data[hash].gpxFileName,
        }));

        setGpxOptions(gpxEntries);
        setGpxData(data.data);

        setMqttHost(data.mqttHost || '');
        setMqttClientID(data.mqttClientID || '');
        setMqttUsername(data.mqttUsername || '');
        setMqttPassword(data.mqttPassword || '');
        setMqttChannel(data.mqttChannel || '');
        setMqttPayload(data.mqttPayload || '');
      }

      await transaction.done;
    } catch (error) {
      console.error("Error loading from IndexedDB:", error);
    }
  }, []);

  const getLocationTemplates = useCallback(async () => {
    try {
      const templates = await Promise.all(
        locationTemplatesName.map(async (loc) => {
          const response = await import(`../assets/json/location-simulator/${loc}.json`);
          return response;
        })
      );
      return templates;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  
  const saveConfigToIndexedDB = useCallback(async (templates = []) => {
    try {
      const db = await initDb();
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const existingData = await store.get(appKey) || { key: appKey, data: {} };

      templates.forEach((template, index) => {
        const templateHash = generateMD5(index.toString());
        if (!existingData.data[templateHash]) {
          existingData.data[templateHash] = {
            gpxFileName: template.name,
            locationRoute: template.locations,
          };
        }
      });
      
      if (currentHash) {
        existingData.data[currentHash] = {
          gpxFileName,
          locationRoute,
        };
      }

      existingData.mqttHost = mqttHost;
      existingData.mqttClientID = mqttClientID;
      existingData.mqttUsername = mqttUsername;
      existingData.mqttPassword = mqttPassword;
      existingData.mqttChannel = mqttChannel;
      existingData.mqttPayload = mqttPayload;

      await store.put(existingData);
      await transaction.done;

      await loadConfigFromIndexedDB();
    } catch (error) {
      console.error('Kesalahan saat menyimpan ke IndexedDB:', error);
    }
  }, [mqttHost, mqttClientID, mqttUsername, mqttPassword, mqttChannel, mqttPayload, gpxFileName, locationRoute, currentHash, loadConfigFromIndexedDB]);

  const getPayloadTemplates = async () => {
    try {
      const templates = await Promise.all(
        payloadTemplatesName.map(async (payload) => {
          const response = await import(`../assets/json/location-simulator/${payload}.json`);
          return response;
        })
      );
      setPayloadTemplates(templates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePayloadTemplateChange = (value: string) => {
    if (value === 'custom-payload') {
      setSelectedPayloadTemplate('custom-payload');
    } else {
      setSelectedPayloadTemplate(value);
      setMqttPayload(atob(payloadTemplates[value]['payload']));
      toast.success('Payload template `' + payloadTemplates[value].name + '` loaded');
    }
  }

  const handleLocationTemplateChange = (hash: string) => {
    if (hash === "custom") {
      setCurrentHash(null);
      setLocationRoute([]);
      return;
    }

    setCurrentHash(hash);
    const selectedGpx = gpxData[hash];

    if (selectedGpx) {
      setGpxFileName(selectedGpx.gpxFileName || "");
      setLocationRoute(selectedGpx.locationRoute || []);
      toast.success(`GPX file '${selectedGpx.gpxFileName}' loaded`);
    }
  };

  const handleStartTask = () => {
    console.log('Starting task...')
    toast.success('Starting task, see the Dev Console for details');
    setIsTaskRunning(true);
  }

  const handleStopTask = () => {
    console.log('Stopping task...')
    toast.success('Task stopped');
    setIsTaskRunning(false);
  }

  const handleLoadGPX = (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setGpxFileName(file.name);
    const fileHash = generateMD5(file.name + file.size + file.lastModified);
    setCurrentHash(fileHash);
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const xml = e.target.result;
  
      try {
        const parserOptions = {
          attributeNamePrefix: '_attr_',
          ignoreAttributes: false,
        };
        const parser = new XMLParser(parserOptions);
        let parsedXml = parser.parse(xml);
  
        const points = parsedXml.gpx.wpt.map((wpt) => [
          parseFloat(wpt._attr_lat),
          parseFloat(wpt._attr_lon),
        ]);
  
        setLocationRoute(points);
        toast.success(`GPX file '${file.name}' loaded successfully`);
  
        await saveConfigToIndexedDB();
        await loadConfigFromIndexedDB();
  
        const selectElement = document.getElementById('gpx-select') as HTMLSelectElement;
        if (selectElement) {
            selectElement.value = fileHash;
        }
      } catch (parseError) {
        console.error('Error extracting points from GPX:', parseError);
        toast.error('Error extracting points from GPX');
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    loadConfigFromIndexedDB();
    getPayloadTemplates();
    setSelectedPayloadTemplate('custom-payload');
  }, [loadConfigFromIndexedDB]);
  
  useEffect(() => {
    if (mqttHost || mqttClientID || mqttUsername || mqttPassword || mqttChannel || mqttPayload || locationRoute.length > 0) {
      const loadTemplates = async () => {
        const templates = await getLocationTemplates();
        if (templates) {
          saveConfigToIndexedDB(templates);
        }
      };
      loadTemplates();
    }
  }, [mqttHost, mqttClientID, mqttUsername, mqttPassword, mqttChannel, mqttPayload, locationRoute, gpxFileName,getLocationTemplates, saveConfigToIndexedDB]);

  useEffect(() => {
    if (!isTaskRunning) return;

    // Send message
    const handleSendMqttMessage = (latitude, longitude) => {
      // Connect to MQTT broker
      const client = mqtt.connect(`wss://${mqttUsername}:${mqttPassword}@${mqttHost}`);

      // Prepare payload
      let payload = mqttPayload.replace(/{{timestamp}}/g, (Math.round(new Date().getTime() / 1000)).toString());
      payload = payload.replace(/{{latitude}}/g, latitude);
      payload = payload.replace(/{{longitude}}/g, longitude);
      console.log('payload', payload);

      // Publish message to MQTT channel
      client.on('connect', () => {
        client.publish(mqttChannel, payload, (err) => {
          if (err) {
            console.error('Error publishing MQTT message:', err);
          } else {
            console.log('MQTT message sent successfully.');
          }
          client.end(); // Close MQTT connection
        });
      });
    };

    let interval;

    // Loop
    let i = 1;
    const runnerLoop = () => {
      setTimeout(() => {
        console.log('Loc: ' + i);
        handleSendMqttMessage(locationRoute[i - 1][0], locationRoute[i - 1][1]);

        if (i === locationRoute.length) {
          clearInterval(interval);
          setIsTaskRunning(false);
          toast.success('Task finished!');
        }

        i++;
        if (i <= locationRoute.length) {
          runnerLoop();
        }
      }, 3 * 1000);
    };

    runnerLoop();
  }, [isTaskRunning, locationRoute, mqttChannel, mqttHost, mqttPayload, mqttUsername, mqttPassword]);

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">
          Location Simulator
        </div>
        {/* Menu */}
        <div className="flex mb-2">
          {/* Control Container */}
          <div className="flex items-center space-x-2">
            <div className="flex items-center">
              <div className="mb-2 inline-block mr-2">Control</div>
              {isTaskRunning ? (
                <button className="btn btn-xs mr-1" onClick={handleStopTask}>
                  Stop
                </button>
              ) : (
                <>
                  {locationRoute.length > 0 ? (
                    <button className="btn btn-xs mr-1" onClick={handleStartTask}>
                      Start
                    </button>
                  ) : (
                    <button className="btn btn-xs mr-1" disabled={true}>
                      Select Location
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="flex-1 flex justify-end items-center space-x-2 ml-auto">
            <div className="relative mr-4">
              {isTaskRunning ? (
                <div className="text-green-400">Running...</div>
              ) : (
                <div className="text-gray-500">Stopped</div>
              )}
            </div>
          </div>
        </div>

        {/* Maps */}
        <div className="relative grid grid-cols-2 gap-4 h-[80vh]">
          <div className="relative mb-2">
            {/* Map */}
            <MapContainer
              bounds={bounds}
              className="block z-10 rounded-lg overflow-hidden h-full w-full"
            >
              {locationRoute.length >= 2 && (
                <>
                  <Marker position={locationRoute[0]} icon={customDivIcon}>
                    <Popup>Start</Popup>
                  </Marker>
                  <Marker position={locationRoute[locationRoute.length - 1]} icon={customDivIcon}>
                    <Popup>Finish</Popup>
                  </Marker>
                </>
              )}
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {locationRoute.length >= 2 && (
                <Polyline pathOptions={{ color: 'blue' }} positions={locationRoute} />
              )}
            </MapContainer>

            {/* Select Template Dropdown */}
            <div className="absolute top-[-2.2rem] right-[-0.1rem] z-20 flex space-x-2">
              <div className="absolute left-[-4.5rem] z-20 flex space-x-2">
                <label htmlFor="gpx-upload" className="btn btn-xs">
                  Load GPX
                </label>
                <input
                  id="gpx-upload"
                  type="file"
                  accept=".gpx"
                  onChange={handleLoadGPX}
                  className="hidden"
                />
              </div>
              <select
                id="gpx-select"
                className="select select-bordered select-xs max-w-xs"
                onChange={(e) => handleLocationTemplateChange(e.target.value)}
              >
                <option key={'custom'} value={'custom'}>Select Template</option>
                {gpxOptions.map((gpxEntry) => (
                  <option key={gpxEntry.hash} value={gpxEntry.hash}>
                    {gpxEntry.gpxFileName}
                  </option>
                ))}
              </select>
            </div>
            {gpxFileName && (
              <div className="absolute bottom-2 left-2 bg-white p-2 rounded shadow">
                <span className="text-sm font-medium">Loaded GPX: {gpxFileName}</span>
              </div>
            )}
          </div>
          {/* Right form */}
          <div className="flex flex-col space-y-4 p-2">
            <div className="flex flex-col space-y-2">
              <div>
                <div className="my-2">MQTT Host:</div>
                <div className="flex items-center space-x-2">
                  <input
                    className="flex-1 input input-bordered input-sm"
                    value={mqttHost}
                    onChange={(e) => setMqttHost(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <CopyButton text={mqttHost} />
                  </div>
                </div>
              </div>
              <div>
                <div className="my-2">Client ID:</div>
                <div className="flex items-center space-x-2">
                  <input
                    className="flex-1 input input-bordered input-sm"
                    value={mqttClientID}
                    onChange={(e) => setMqttClientID(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <CopyButton text={mqttClientID} />
                  </div>
                </div>
              </div>
              <div>
                <div className="my-2">Username:</div>
                <div className="flex items-center space-x-2">
                  <input
                    className="flex-1 input input-bordered input-sm"
                    value={mqttUsername}
                    onChange={(e) => setMqttUsername(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <CopyButton text={mqttUsername} />
                  </div>
                </div>
              </div>
              <div>
                <div className="my-2">Password:</div>
                <div className="flex items-center space-x-2">
                  <input
                    className="flex-1 input input-bordered input-sm"
                    value={mqttPassword}
                    onChange={(e) => setMqttPassword(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <CopyButton text={mqttPassword} />
                  </div>
                </div>
              </div>
              <div>
                <div className="my-2">Channel:</div>
                <div className="flex items-center space-x-2">
                  <input
                    className="flex-1 input input-bordered input-sm"
                    value={mqttChannel}
                    onChange={(e) => setMqttChannel(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <CopyButton text={mqttChannel} />
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-2 gap-4 my-2">
                  <div className="">Payload:</div>
                    <div className="text-right">
                    <select
                      className="select select-bordered select-xs max-w-xs"
                      value={selectedPayloadTemplate}
                      onChange={(e) => handlePayloadTemplateChange(e.target.value)}
                    >
                      <option key={'cp-0'} value={'custom-payload'}>
                        Custom
                      </option>
                      {payloadTemplates.map((template, index) => (
                        <option key={index} value={index}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <textarea
                  rows={5}
                  onChange={(e) => setMqttPayload(e.target.value)}
                  className="textarea w-full textarea-bordered"
                  placeholder="Payload"
                  value={mqttPayload}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationSimulator;