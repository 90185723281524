import {useState, useEffect, useCallback} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {EditorView, Decoration, ViewPlugin} from "@codemirror/view";
import {RangeSetBuilder} from "@codemirror/state";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {diffLines, diffWords, diffChars} from 'diff';

export default function DiffChecker() {
    // ========================
    // Business Logic Variables
    // ========================

    // Comparison mode, can be 'characters', 'words', or 'lines'. Default is 'characters' mode
    const [diffMode, setDiffMode] = useState("characters");

    // ==================
    // UI Logic Variables
    // ==================

    // Original text to be used in comparison
    const [originalText, setOriginalText] = useState("");

    // Text to be compared against the original text
    const [comparedText, setComparedText] = useState("");

    // Result of the comparison (plain text format for display in CodeMirror)
    const [result, setResult] = useState("");

    // Decorations for highlighting differences
    const [decorations, setDecorations] = useState(null);
    
    // =====================
    // Business Logic Handle
    // =====================
    
    /**
    * Main function to generate the differences between original and compared text.
    * Selects `diffFunction` based on `diffMode` as set by the user.
    * Computes the diff result and applies it as decorations in the editor.
    */
    const generateDiff = useCallback(() => {
        let diffFunction;

        // Select the diff function based on the comparison mode (characters, words, or lines)
        switch (diffMode) {
            case "words":
                diffFunction = diffWords;
                break;
            case "lines":
                diffFunction = diffLines;
                break;
            default:
                diffFunction = diffChars;
        }

        // Generate diff between originalText and comparedText
        const diff = diffFunction(originalText, comparedText);

        // Concatenate the diff result as plain text without styling
        const plainResult = diff.map(part => part.value).join("");
        setResult(plainResult);

        // Create decorations to highlight added and removed text
        const builder = new RangeSetBuilder();
        let pos = 0;

        // For each part of the diff, add a decoration based on its status (added or removed)
        diff.forEach((part) => {
            const len = part.value.length;
            if (part.added) {
                builder.add(
                    pos,
                    pos + len,
                    Decoration.mark({ class: "diff-added" })
                );
            } else if (part.removed) {
                builder.add(
                    pos,
                    pos + len,
                    Decoration.mark({ class: "diff-removed" })
                );
            }
            pos += len;
        });

        // Store the final decorations in the `decorations` state
        setDecorations(builder.finish());
    },[originalText, comparedText, diffMode]);

    /**
    * Decoration plugin for CodeMirror, applying decorations from the `decorations` state
    * to show differences between the texts.
    */
    const decorationPlugin = ViewPlugin.fromClass(
        class {
            decorations() {
                return decorations || Decoration.none;
            }
        },
        { decorations: () => decorations }
    );

    /**
    * Calls generateDiff whenever `originalText`, `comparedText`, or `diffMode` changes.
    */
    useEffect(() => {
        generateDiff();
    }, [generateDiff]);

    // ===============
    // UI Logic Handle
    // ===============

    /**
    * Provides a custom theme to highlight added and removed text.
    */
    const diffTheme = EditorView.baseTheme({
        ".diff-added": {
          backgroundColor: "#4CAF50",  // Green background for added text
          color: "white", // White text color for better contrast
        },
        ".diff-removed": {
          backgroundColor: "#F44336", // Red background for removed text
          color: "white", // White text color for better contrast
        },
    });
    
    /**
    * Handles changes in the original text editor.
    * @param {string} value - New text input by the user
    */
    const handleOriginalTextChange = (value) => {
        // NB: Fungsi ini sudah berhasil.
        setOriginalText(value);
    };

    /**
    * Handles changes in the compared text editor.
    * @param {string} value - New text input by the user
    */
    const handleComparedTextChange = (value) => {
        // NB: Fungsi ini sudah berhasil.
        setComparedText(value);
    };

    /**
    * Copies either the original or compared text to the clipboard based on the `value` parameter.
    * @param {string} value - "original" or "compared" to determine which text to copy
    */
    const handleClipboard = (value) => {
        let temp = "";
        switch(value) {
            case "original":
                temp = originalText;
                break;
            case "compared":
                temp = comparedText;
                break;
            default:
                break;
        };

        navigator.clipboard.writeText(temp)
    };

    /**
    * Fills the editor with sample text to facilitate testing.
    * @param {string} value - "original" or "compared" to determine which text editor to populate with sample data
    */
    const handleSample = (value) => {
        switch(value) {
            case "original":
                setOriginalText("Sample original text");
                break;
            case "compared":
                setComparedText("Sample compared text");
                break;
            default:
                break;
        };
    };

    /**
    * Clears the text in the editor based on the `value` parameter.
    * @param {string} value - "original" or "compared" to determine which text to clear
    */
    const handleClear = (value) => {
        switch(value) {
            case "original":
                setOriginalText("");
                break;
            case "compared":
                setComparedText("");
                break;
            default:
                break;
        };
    };

    /**
    * Swaps the content between the original text and the compared text.
    */
    const handleSwapInputs = () => {
        const temp = originalText;
        setOriginalText(comparedText);
        setComparedText(temp);
    };
    
    return (
        <div className="h-screen flex">
            <div className="w-full mx-6 my-4">
                <div className="w-full flex items-center font-bold mb-3">Diff Checker</div>
                    <div className="grid grid-cols-2 gap-4">
                        {/* Original Text Container */}
                        <div>
                            <div className="flex mb-2">
                                <div className="mb-2 inline-block">Original Text:</div>
                                <div className="inline-block ml-3">
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleClipboard("original")}>
                                        Clipboard
                                    </button>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleSample("original")}>
                                        Sample
                                    </button>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleClear("original")}>
                                        Clear
                                    </button>
                                </div>
                            </div>
                            <CodeMirror
                                className="bg-gray-900 w-full pb-[40vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[40vh] max-h-[40vh]"
                                placeholder="Enter text here"
                                extensions={[EditorView.lineWrapping]}
                                height="40vh"
                                theme={dracula}
                                value={originalText}
                                onChange={(value) => handleOriginalTextChange(value)}
                            />
                        </div>

                        {/* Compared Text Container */}
                        <div>
                            <div className="flex mb-2">
                                <div className="mb-2 inline-block">Compared Text:</div>
                                <div className="inline-block ml-3">
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleClipboard("compared")}>
                                            Clipboard
                                    </button>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleSample("compared")}>
                                        Sample
                                    </button>
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleClear("compared")}>
                                        Clear
                                    </button>
                                </div>
                                <div className="ml-auto">
                                    <button
                                        className="btn btn-xs mr-1"
                                        onClick={() => handleSwapInputs()}>
                                        Swap Inputs
                                    </button>
                                </div>
                            </div>
                            <CodeMirror
                                className="bg-gray-900 w-full pb-[40vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[40vh] max-h-[40vh]"
                                placeholder="Enter text here"
                                extensions={[EditorView.lineWrapping]}
                                height="40vh"
                                theme={dracula}
                                value={comparedText}
                                onChange={(value) => handleComparedTextChange(value)}
                            />
                        </div>
                    </div>

                {/* Comparison Result Container */}
                <div className="mt-6">
                    {/* Diff Mode Selector */}
                    <div className="mt-6 flex  items-center gap-4 justify-between">
                        <div className="flex gap-2 items-center mb-3">
                            <span>Diff mode:</span>
                            <label>
                                <input
                                    type="radio"
                                    value="characters"
                                    className="mr-2"
                                    checked={diffMode === "characters"}
                                    onChange={() => setDiffMode("characters")}
                                />
                                    Characters
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="words"
                                    className="mr-2"
                                    checked={diffMode === "words"}
                                    onChange={() => setDiffMode("words")}
                                />
                                    Words
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="lines"
                                    className="mr-2"
                                    checked={diffMode === "lines"}
                                    onChange={() => setDiffMode("lines")}
                                />
                                    Lines
                            </label>
                        </div>

                        {/* Output Type Selector */}
                        {/* <div className="flex gap-1 items-center mb-3">
                            <span>Output:</span>
                            <select
                                className="btn btn-xs mr-1 text-left"
                                value={outputFormat}
                                onChange={(e) => setOutputFormat(e.target.value)}
                            >
                                    <option value="formatted">Formatted Text</option>
                                    <option value="raw">Raw Text</option>
                            </select>
                            <span className="btn btn-xs  mr-1 ml-1">Changes: </span>
                        </div> */}
                    </div>
                    {/* Diff section with CodeMirror */}
                    <CodeMirror
                        className="bg-gray-900 w-full pb-[40vh] border border-gray-700 rounded-md overflow-hidden min-h-[40vh] max-h-[40vh]"
                        extensions={[EditorView.lineWrapping, decorationPlugin, diffTheme]}
                        theme={dracula}
                        height="40vh"
                        value={result}
                        readOnly={true}
                    />
                </div>
            </div>
            
        </div>
    );
};