import React, { useState, useEffect, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import CopyButton from "../components/CopyButton";
import { encode, decode } from "html-entities";
import sampleHTML from "../assets/json/html.json";
import rehypeFormat from "rehype-format";
import rehypeParse from "rehype-parse";
import rehypeStringify from "rehype-stringify";
import { unified } from "unified";

function HTMLBeautifyMinify() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isEncoding] = useState(true);
  const [invalidHtmlMessage, setInvalidHtmlMessage] = useState("");
  const [spaces, setSpaces] = useState(2);

  const formatHTML = useCallback(async () => {
    const processor = unified()
      .use(rehypeParse)
      .use(rehypeFormat, { indent: spaces })
      .use(rehypeStringify);

    try {
      const formattedHTML = await processor.process(inputText);
      setOutputText(String(formattedHTML));
    } catch (error) {
      setOutputText(inputText);
      setInvalidHtmlMessage("Invalid HTML");
    }
  }, [inputText, spaces]);

  const handleInputChange = useCallback((value, viewUpdate) => {
    setInputText(value);
  }, []);

  const convertText = useCallback(
    (text) => {
      try {
        if (isEncoding) {
          const encoder = encode(text);
          setOutputText(encoder);
        } else {
          const decoder = decode(text);
          setOutputText(decoder);
        }
      } catch (error) {
        setOutputText(
          isEncoding ? "Error encoding text" : "Invalid Base64 string"
        );
      }
    },
    [isEncoding]
  );

  const handleSample = async () => {
    setInputText(isEncoding ? sampleHTML[0] : decode(sampleHTML[0]));
    convertText(isEncoding ? sampleHTML[0] : decode(sampleHTML[0]));
  };

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  const handleClear = async () => {
    setInputText("");
  };

  useEffect(() => {
    if (inputText.trim() === "") {
      setOutputText("");
      setInvalidHtmlMessage("");
      return;
    }

    formatHTML();
  }, [inputText, spaces, formatHTML]);

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">
          HTML Beautify/Minify
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Input:</div>
              <div className="inline-block ml-3">
                <button
                  className="btn btn-xs  mr-1"
                  onClick={handlePasteFromClipboard}
                >
                  Clipboard
                </button>
                <button className="btn btn-xs mr-1" onClick={handleSample}>
                  Sample
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>

            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[78.5vh] max-h-[78.5vh]"
              placeholder="Enter HTML here"
              extensions={[EditorView.lineWrapping]}
              value={inputText}
              onChange={handleInputChange}
              height="78.5vh"
              theme={dracula}
            />
          </div>
          <div>
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-2 py-[5px] px-[10px]">
                <p>Output:</p>
                <p className="text-red-500">{invalidHtmlMessage}</p>
              </div>
              <div className="flex items-center gap-2">
                <select
                  className="select select-bordered select-xs"
                  value={spaces}
                  onChange={(e) => setSpaces(Number(e.target.value))}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                    <option key={num} value={num}>
                      {num} Spaces
                    </option>
                  ))}
                </select>
                <CopyButton text={outputText} />
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]"
              theme={dracula}
              value={outputText}
              height="78.5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HTMLBeautifyMinify;