import { useState } from "react";
import toast from "react-hot-toast";

import CopyButton from "../components/CopyButton";
import sampleLorem from "../assets/json/lorem.json";

export default function CaseConverter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const convertedText = e.target.value;
    setInputText(convertedText);
  };

  const handleSample = () => {
    setInputText(sampleLorem[0]);
  };

  const handlePasteFromClipboard = async () => {
    try {
      const clipBoard = await navigator.clipboard.readText();
      setInputText(clipBoard);
      toast.success("Paste value from Clipboard");
    } catch (error) {
      console.error("Hash error: " + error);
    }
  };

  const handleClear = () => {
    setInputText("");
    setOutputText("");
  };

  const handleConvertToLowercase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.toLowerCase();
    setOutputText(convertedText);
  };

  const handleConvertToUppercase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.toUpperCase();
    setOutputText(convertedText);
  };

  const handleConvertToCamelCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.replace(/\W+(.)/g, (_, chr) =>
      chr.toUpperCase()
    );
    setOutputText(convertedText);
  };

  const handleConvertToCapitalCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());
    setOutputText(convertedText);
  };

  const handleConvertToConstantCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.toUpperCase().replace(/ /g, "_");
    setOutputText(convertedText);
  };

  const handleConvertToDotCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.toLowerCase().replace(/ /g, ".");
    setOutputText(convertedText);
  };

  const handleConvertToHeaderCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
    setOutputText(convertedText);
  };

  const handleConvertToParamsCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.replace(/\s+/g, "-").toLowerCase();
    setOutputText(convertedText);
  };

  const handleConvertToPascalCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const words = inputText.split(/\s+/);
    const convertedText = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
    setOutputText(convertedText);
  };

  const handleConvertToPathCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText
      .toLowerCase()
      .replace(/\s+/g, "/")
      .replace(/[^a-z0-9-]/g, "/");
    setOutputText(convertedText);
  };

  const handleConvertToSentenceCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText =
      inputText.charAt(0).toUpperCase() + inputText.slice(1).toLowerCase();
    setOutputText(convertedText);
  };

  const handleConvertToSnakeCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText.toLowerCase().replace(/\s+/g, "_");
    setOutputText(convertedText);
  };

  const handleConvertToSwapCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = inputText
      .split("")
      .map((char) =>
        char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
      )
      .join("");
    setOutputText(convertedText);
  };

  const handleConvertToTitleCase = () => {
    if (inputText.trim() === "") {
      toast.error("Please fill the input");
      return;
    }

    const convertedText = toTitleCase(inputText);
    setOutputText(convertedText);
  };

  const caseTypes = [
    { label: "lowercase", handler: handleConvertToLowercase },
    { label: "UPPERCASE", handler: handleConvertToUppercase },
    { label: "camelCase", handler: handleConvertToCamelCase },
    { label: "Capital Case", handler: handleConvertToCapitalCase },
    { label: "CONSTANT_CASE", handler: handleConvertToConstantCase },
    { label: "dot.case", handler: handleConvertToDotCase },
    { label: "Header-Case", handler: handleConvertToHeaderCase },
    { label: "params-case", handler: handleConvertToParamsCase },
    { label: "PascalCase", handler: handleConvertToPascalCase },
    { label: "path/case", handler: handleConvertToPathCase },
    { label: "Sentence case", handler: handleConvertToSentenceCase },
    { label: "snake_case", handler: handleConvertToSnakeCase },
    { label: "sWAP cASE", handler: handleConvertToSwapCase },
    { label: "Title Case", handler: handleConvertToTitleCase },
  ];

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">
          Case Converter
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <div>Input:</div>
              <div className="flex items-center gap-1">
                <button onClick={handlePasteFromClipboard} className="btn btn-xs">
                  Clipboard
                </button>
                <button onClick={handleSample} className="btn btn-xs">
                  Sample
                </button>
                <button onClick={handleClear} className="btn btn-xs">
                  Clear
                </button>
              </div>
            </div>
            <textarea
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[20vh] max-h-[50vh] text-white"
              placeholder="Enter text here"
              value={inputText}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-row flex-wrap gap-2 items-center">
            {caseTypes.map((caseType, index) => (
              <button
                key={index}
                className="btn btn-xs normal-case"
                onClick={caseType.handler}
              >
                {caseType.label}
              </button>
            ))}
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <p>Output:</p>
              <CopyButton text={outputText ? outputText : "-"} />
            </div>
            <textarea
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[20vh] max-h-[50vh] text-white"
              value={outputText}
            />
          </div>
        </div>
      </div >
    </div>
  );
}

const toTitleCase = (input) => {
  let i, j, str, lowers, uppers;
  str = input.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
    "The",
    "Over",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\s" + lowers[i] + "\\s", "g"),
      function (txt: string) {
        return txt.toLowerCase();
      }
    );

  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\b" + uppers[i] + "\\b", "g"),
      uppers[i].toUpperCase()
    );

  return str;
};
