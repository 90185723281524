import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { LatLngBounds } from "leaflet";
import { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const JSONLocationViewer = () => {
  const [jsonURL, setJsonURL] = useState("");
  const [locations, setLocations] = useState([]);

  const handleSubmit = () => {
    fetch(jsonURL)
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  };

  const AdjustBounds = ({ locations }: { locations }) => {
    const map = useMap();
    useEffect(() => {
      if (locations.length > 0) {
        const bounds = new LatLngBounds(
          locations.map((location) => [location.Lat, location.Lng])
        );
        map.fitBounds(bounds);
      }
    }, [locations, map]);
    return null;
  };

  const handleRefresh = () => {
    setJsonURL("");
    setLocations([]);
  };

  return (
    <div className="relative w-full h-full px-6 py-4 bg-[#212936]">
      <h1 className="text-base font-bold text-white mb-4">JSON Location Viewer</h1>
      <div className="mb-4">
        <button className="btn btn-sm text-white" onClick={() => handleRefresh()}>
          Refresh
        </button>
      </div>
      <div className="flex flex-row items-center mb-4 space-x-2 w-full">
        <label className="text-white text-sm font-bold whitespace-nowrap">
          JSON URL
        </label>
        <input
          type="text"
          value={jsonURL}
          onChange={(e) => setJsonURL(e.target.value)}
          placeholder="https://example.com/data.json"
          className="input input-bordered w-full"
        />
        <button className="btn btn-sm text-white" onClick={() => handleSubmit()}>
          Submit
        </button>
      </div>

      <div className="w-full h-[calc(100vh-200px)] rounded-lg overflow-hidden">
        <MapContainer
          className="h-full w-full"
          zoom={12}
          center={[1.3521, 103.8198]}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <AdjustBounds locations={locations} />
          <MarkerClusterGroup showCoverageOnHover={true} zoomToBoundsOnClick={true}>
            {locations.map((location, index) => (
              <Marker key={index} position={[location.Lat, location.Lng]}>
                <Popup>
                  <div>
                    <p><strong>Assignment ID:</strong> {location.AssignmentId}</p>
                    <p><strong>Vehicle ID:</strong> {location.VehicleId}</p>
                    <p><strong>Driver ID:</strong> {location.DriverId}</p>
                    <p><strong>Created At:</strong> {location.CreatedAt}</p>
                    <p><strong>Coordinates:</strong> ({location.Lat}, {location.Lng})</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </div>
  );
};

export default JSONLocationViewer;
