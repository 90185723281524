import { BrowserRouter, Route, Routes } from "react-router-dom";
import Formatter from "./tools/JsonFormatter";
import { useEffect, useState } from "react";
import UnixTimeConverter from "./tools/UnixTimeConverter";
import UUIDGenerator from "./tools/UUIDGenerator";
import QRCodeGenerator from "./tools/QRCodeGenerator";
import BcryptHasher from "./tools/BcryptHasher";
import JsonFormatter from "./tools/JsonFormatter";
import { Toaster } from "react-hot-toast";
import Argon2HashGenerator from "./tools/Argon2HashGenerator";
import CaseConverter from "./tools/CaseConverter";
import HashGenerator from "./tools/HashGenerator";
import Base64Converter from "./tools/Base64Converter";
import HTMLEntityConverter from "./tools/HTMLEntityConverter";
import Base64ImageConverter from "./tools/Base64ImageConverter";
import JSONtoPHP from "./tools/JSONtoPHP";
import RandomStringGenerator from "./tools/RandomStringGenerator";
import LocationSimulator from "./tools/LocationSimulator";
import SVGEditor from "./tools/SVGEditor";
import Postman from "./tools/Postman";
import ULIDGenerator from "./tools/ULIDGenerator";
import MagicCodeConverter from "./tools/MagicCodeConverter";
import HTMLBeautifyMinify from "./tools/HTMLBeautifyMinify";
import MagicSimplerCoder from "./tools/MagicSimpleCoder";
import SVGtoSVGR from "./tools/SVGtoSVGR";
import UptimeChecker from "./tools/UptimeChecker";
import { useStore } from "./index";
import { observer } from "mobx-react-lite";
import ClosedSidebar from "./ClosedSidebar";
import OpenedSidebar from "./OpenedSidebar";
import LLMChat from "./tools/LLMChat";
import Notepad from "./tools/Notepad";
import Pomodoro from "./tools/Pomodoro";
import MultiUptimeChecker from "./tools/MultiUptimeChecker";
import LineSort from "./tools/LineSort";
import SMTPMailDev from "./tools/SMTPMailDev";
import DiffChecker from "./tools/DiffChecker";
import DistanceCalculator from "./tools/DistanceCalculator";
import JSONLocationViewer from "./tools/Jsonlocationviewer";
import LogViewer from "./tools/LogViewer";

// Configs
export const DEV_FEEDBACK_EMAIL = "kim.hudaya+devtool@taksu.tech";

const App = observer(() => {
  // Stores
  const { sidebarStore } = useStore();

  // States
  const [isPopupVisible] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [topContributor, setTopContributor] = useState([]);

  // Functions
  const handleSendFeedback = () => {
    const subject = "DevTool Feedback";
    const body = encodeURIComponent(feedbackContent);

    const mailtoLink = `mailto:${DEV_FEEDBACK_EMAIL}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  // Use Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("contributor.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTopContributor(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (sidebarStore.isLoading) {
    return null;
  };

  return (
    <BrowserRouter>
      <div className="h-screen flex bg-gray-800 text-white overflow-hidden">
        {/* Sidebar */}
        {sidebarStore.isOpen ? <OpenedSidebar /> : <ClosedSidebar />}

        {/* Main Content */}
        <div
          className={`${sidebarStore.isOpen ? "w-3/4 ml-[25%]" : "w-full ml-[50px]"} overflow-auto`}
        >
          <Routes>
            <Route path="/" element={<Formatter />} />
            <Route path="/llm-chat" element={<LLMChat />} />
            <Route path="/json-formatter" element={<JsonFormatter />} />
            <Route
              path="/base64-string-encode-decode"
              element={<Base64Converter />}
            />
            <Route
              path="/unix-time-converter"
              element={<UnixTimeConverter />}
            />
            <Route path="/uuid-generator" element={<UUIDGenerator />} />
            <Route path="/bcrypt-hasher" element={<BcryptHasher />} />
            <Route path="/qr-code-generator" element={<QRCodeGenerator />} />
            <Route
              path="/argon2-hash-generator"
              element={<Argon2HashGenerator />}
            />
            <Route path="/case-converter" element={<CaseConverter />} />
            <Route path="/hash-generator" element={<HashGenerator />} />
            <Route
              path="/html-entity-encode-decode"
              element={<HTMLEntityConverter />}
            />
            <Route
              path="/base64-image-encode-decode"
              element={<Base64ImageConverter />}
            />
            <Route path="/json-to-php" element={<JSONtoPHP />} />
            <Route
              path="/random-string-generator"
              element={<RandomStringGenerator />}
            />
            <Route
              path="/diff-checker"
              element={<DiffChecker />}
            />
            <Route path="/location-simulator" element={<LocationSimulator />} />
            <Route path="/svg-editor" element={<SVGEditor />} />
            <Route path="/postman-exporter" element={<Postman />} />
            <Route path="/ulid-generator" element={<ULIDGenerator />} />
            <Route
              path="/magic-code-converter"
              element={<MagicCodeConverter />}
            />
            <Route
              path="/html-beautify-minify"
              element={<HTMLBeautifyMinify />}
            />
            <Route path="/magic-simple-coder" element={<MagicSimplerCoder />} />
            <Route path="/svg-to-svgr" element={<SVGtoSVGR />} />
            <Route path="/uptime-checker" element={<UptimeChecker />} />
            <Route path="/notepad" element={<Notepad />} />
            <Route path="/pomodoro" element={<Pomodoro />} />
            <Route path="/line-sort" element={<LineSort />} />
            <Route
              path="/multi-uptime-checker"
              element={<MultiUptimeChecker />} // ini untuk penambahan path yang baru yang di samping kiri atas feedback //
            />
            <Route path="/smtp-maildev" element={<SMTPMailDev />} />
            <Route path="/distance-calculator" element={<DistanceCalculator />} />
            <Route path="/json-location-viewer" element={<JSONLocationViewer />} />
            <Route path="/log-viewer" element={<LogViewer />} />


          </Routes>

        </div>
      </div>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my_modal_6" className="modal-toggle" />
      <div className={`modal ${isPopupVisible ? "open" : ""}`}>
        <div
          className="modal-box px-7 pt-3 "
          style={{
            background: "rgba(53, 51, 51, 0.78)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5.4px)",
            WebkitBackdropFilter: "blur(5.4px)",
          }}
        >
          <form method="dialog">
            <label
              htmlFor="my_modal_6"
              className="btn bg-white text-black text-md btn-xs btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </label>
          </form>
          <h3 className="font-bold text-md text-white mb-3">
            Feedback & About
          </h3>
          <textarea
            className="w-full mx-auto rounded-lg h-32 bg-gray-950 p-1 text-white"
            placeholder=""
            value={feedbackContent}
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
          <button
            onClick={handleSendFeedback}
            className="mt-3 text-black font-bold w-16 h-8 rounded-md bg-white"
          >
            Send
          </button>
          <p className="font-thin text-lg mt-7 text-white mb-2">
            Developed by <span className="font-bold">Taksu Tech</span>
          </p>
          <p className="text-white mb-[-2vh]">
            <div className="btn btn-sm btn-outline  mr-1 mb-1">
              Kim Hudaya
              <span className="badge badge-sm">✨</span>
            </div>
            {topContributor.map((contributor, index) => (
              <div key={index} className="inline">
                {contributor.author === "Kim Hudaya" ? (
                  <></>
                ) : (
                  <div
                    className="tooltip tooltip-primary"
                    data-tip="Number of Commit"
                  >
                    <div className="btn btn-sm btn-outline mr-1 mb-1 ">
                      {contributor.author}
                      <span className="badge badge-sm">
                        {contributor.commit}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </p>
          <div className="modal-action"></div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </BrowserRouter>
  );
});

export default App;
