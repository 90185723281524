import { useEffect, useState } from "react";
import bcrypt from "bcryptjs-react";
import toast from "react-hot-toast";

export default function BcryptHash() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [costFactorValue, setCostFactorValue] = useState(10);
  const [outputHashFormatValue, setOutputHashFormatValue] = useState("encoded");

  const copyToClipboard = () => {
    if (inputText.trim() === "") {
      toast.error("Fill the input", {
        duration: 1500, // in ms
      });
      return;
    }

    navigator.clipboard.writeText(outputText);

    toast.success('Copied to Clipboard', {
      duration: 1500, // in ms
    });
  };

  useEffect(() => {
    if (inputText.trim() === "") {
      setOutputText("");
      return;
    }
    const hash = bcrypt.hashSync(
      inputText,
      bcrypt.genSaltSync(costFactorValue)
    );
    const formattedHash = outputHashFormatValue === "hex" ? toHex(hash) : hash;
    setOutputText(formattedHash);
  }, [inputText, costFactorValue, outputHashFormatValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOutputHashFormatValue(e.target.value);
  };

  const toHex = (str: string) => {
    return str
      .split("")
      .map((char) => char.charCodeAt(0).toString(16))
      .join("");
  };

  return (
    <div className="h-full flex">
      {/* Main Content */}
      <div className="w-full mx-6 my-4">
        <div className="w-full flex font-bold mb-3">Bcrypt Hasher</div>

        <div className="inline-block mb-2">Input:</div>
        <input
          className="bg-gray-900 block px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full"
          placeholder="Enter text here"
          value={inputText}
          onChange={handleInputChange}
        />

        <div className="flex gap-8 my-4">
          <div>
            <div className="inline-block mb-2">Cost Factor:</div>
            <select
              className="block select select-bordered w-full max-w-xs"
              value={costFactorValue}
              onChange={(e) => setCostFactorValue(Number(e.target.value))}
            >
              {[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map(
                (num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                )
              )}
            </select>
          </div>

          <div>
            <div className="inline-block mb-2">Output Hash Format:</div>
            <select
              className="block select select-bordered w-full max-w-xs"
              onChange={handleSelectChange}
              value={outputHashFormatValue}
            >
              <option key="1" value="encoded">
                Encoded
              </option>
              <option key="2" value="hex" disabled={true}>
                Hex
              </option>
            </select>
          </div>
        </div>

        <div className="flex justify-between mt-3 mb-2">
          <p>Output:</p>
          <button className="btn btn-sm" onClick={copyToClipboard}>
            copy
          </button>
        </div>
        <textarea
          className="bg-gray-900 w-full  px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          placeholder="E.g. $2a$10$KkX...."
          value={outputText}
          readOnly
        />
      </div>
    </div>
  );
}
