import React, {useEffect, useState} from "react";
import {argon2id, argon2d, argon2i} from "hash-wasm";
import toast from "react-hot-toast";

export default function Argon2HashGenerator() {
  const [plainText, setPlainText] = useState("");
  const [salt, setSalt] = useState("");
  const [parallelism, setParallelism] = useState(1);
  const [memoryCost, setMemoryCost] = useState(512); // in KB
  const [iterations, setIterations] = useState(2);
  const [hashLength, setHashLength] = useState(32);
  const [hashType, setHashType] = useState("argon2i");
  const [outputFormat, setOutputFormat] = useState("encoded");
  const [outputHash, setOutputHash] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = outputHash;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsCopied(true);

    toast.success("Copied to Clipboard", {
      duration: 1500, // in ms
    });

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleHashTypeChange = (type) => {
    setHashType(type);
  };

  const handleGenerateClick = async () => {
    try {
      if (!plainText) {
        toast.error("Please input the Plain Text");
        console.error("Password must be specified");
        return;
      }

      if (!salt) {
        toast.error("Please input Salt");
        return;
      }
      if (salt.length < 8) {
        toast.error("Salt must be greater than 8");
        return;
      }

      console.log("plainText (2)", plainText);

      let hashOptions;

      switch (hashType) {
        case "argon2i":
          hashOptions = await argon2i({
            password: plainText,
            salt: salt,
            parallelism,
            iterations,
            memorySize: memoryCost,
            hashLength,
            outputType: outputFormat as "hex" | "binary" | "encoded",
          });
          break;
        case "argon2d":
          hashOptions = await argon2d({
            password: plainText,
            salt: salt,
            parallelism,
            iterations,
            memorySize: memoryCost,
            hashLength,
            outputType: outputFormat as "hex" | "binary" | "encoded",
          });
          break;
        case "argon2id":
          hashOptions = await argon2id({
            password: plainText,
            salt: salt,
            parallelism,
            iterations,
            memorySize: memoryCost,
            hashLength,
            outputType: outputFormat as "hex" | "binary" | "encoded",
          });
          break;
        default:
          toast.error("Invalid hash type");
          console.error("Invalid hash type");
          return;
      }

      setOutputHash(hashOptions);
    } catch (error) {
      toast.error("Error generating hash");
      console.error("Error generating hash:", error);
    }
  };

  useEffect(() => {
    console.log("plainText", plainText);
  }, [plainText]);

  console.log(outputHash);

  return (
    <div className="h-full flex flex-col">
      {/* Main Content */}
      <div className="mx-6 my-4">
        <div className="w-full flex items-center font-bold mb-3">Argon2 Hasher</div>
          <div className="my-4">
            <p className="font-bold mb-3">Plain Text</p>
            <input
              type="text"
              value={plainText}
              placeholder="Enter your text here..."
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
              onChange={(e) => setPlainText(e.target.value)}
            />
          </div>
        <div className="my-4">
          <p className="font-bold mb-3">Salt</p>
          <input
            type="text"
            value={salt}
            placeholder="Enter your text here..."
            className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            onChange={(e) => setSalt(e.target.value)}
          />
        </div>

        <div className="flex justify-between my-4">
          <div>
            <p className="font-bold mb-3">Parallelism Factor</p>
            <input
              type="number"
              value={parallelism}
              onChange={(e) => setParallelism(Number(e.target.value))}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            />
          </div>
          <div>
            <p className="font-bold mb-3">Memory Cost</p>
            <input
              type="number"
              value={memoryCost}
              onChange={(e) => setMemoryCost(Number(e.target.value))}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            />
          </div>
          <div>
            <p className="font-bold mb-3">Iterations</p>
            <input
              type="number"
              value={iterations}
              onChange={(e) => setIterations(Number(e.target.value))}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            />
          </div>
          <div>
            <p className="font-bold mb-3">Hash Length</p>
            <input
              type="number"
              value={hashLength}
              onChange={(e) => setHashLength(Number(e.target.value))}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            />
          </div>
        </div>
        <div className="my-4 grid grid-cols-2 items-center">
          <div>
            <p className="font-bold mb-3">Hash Type</p>
            <div className="flex">
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input type="radio" name="hashType" className="radio checked:bg-blue-500" checked={hashType === "argon2i"} onChange={() => handleHashTypeChange("argon2i")} />
                  <span className="label-text mx-2 font-bold">Argon2i</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input type="radio" name="hashType" className="radio checked:bg-blue-500" checked={hashType === "argon2d"} onChange={() => handleHashTypeChange("argon2d")} />
                  <span className="label-text mx-2 font-bold">Argon2d</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input type="radio" name="hashType" className="radio checked:bg-blue-500" checked={hashType === "argon2id"} onChange={() => handleHashTypeChange("argon2id")} />
                  <span className="label-text mx-2 font-bold">Argon2id</span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <p className="font-bold mb-3">Output Hash Format</p>
            <select name="outputFormat" id="outputFormat" className="select select-bordered w-full max-w-xs" value={outputFormat} onChange={(e) => setOutputFormat(e.target.value)}>
              <option value="encoded">Encoded</option>
              <option value="hex">Hex</option>
              <option value="binary">Binary</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end">
          <button className="btn btn-primary" onClick={handleGenerateClick}>
            Generate
          </button>
        </div>
        <div className="my-3">
          <p className="font-bold mb-3">Output</p>
          <div className="flex gap-2">
            <input type="text" value={outputHash} readOnly className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white" />
            <button onClick={copyToClipboard} className="bg-gray-900 w-1/12 px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white">
              {isCopied ? "✅" : "📋"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
